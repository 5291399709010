import { inRange, isEmpty, isNumber } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import obj from "../Utils/helpers";
import BackLayHome from "./BackLayHome";
import LiveMatchTracker from "../pages/Score/ScoreBoard";
import AuthContext from "../context/AuthContext";
import { Button } from "react-bootstrap";
import { FaAngleRight, FaLock } from "react-icons/fa";
import growth from "../assets/images/growth.svg";
import BetSlipContext from "../context/BetSlipContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import Slider from "react-slick";
import secureLocalStorage from "react-secure-storage";
import useScroll from "./Scroll";
import { TiArrowSortedUp } from "react-icons/ti";
import SportAccordianInnerSlip from "./SportAccordianInnerSlip";
import { cricketScore, priceFormat } from "../Utils/constants";
const SportAccordianInner = ({
  matchOdds,
  object,
  addToFavioute,
  activeFav,
  setActiveFav,
  keyTime,
  score,
  showScore,
  setShowScore,
  setTimer,
  multiOddsPremium,
  scrollHeight,
  type,
  block,
}) => {
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const {
    user,
    parly,
    setParlyBet,
    parlyBet,
    profileData,
    setScoreDetail,
    matchLimit,
    gameTab,
    setTimeLeft,
  } = useContext(AuthContext);
  let limitList =
    matchLimit?.length > 0
      ? matchLimit?.find((res) => {
          return (
            res?.betfairId == object?.eventType && res?.market == "Match Odds"
          );
        })
      : {};
  const navigate = useNavigate();
  const [runner, setRunner] = useState([]);
  const [select, setSelect] = useState({});
  const {
    setBetSlipObject,
    setShowBetDialog,
    showBetDialog,
    betSlipObject,
    resetBetPosition,
    resetBetSlipObject,
    setResetBetPosition,
    setLimit,
    betPlace,
    setBetLoader,
  } = useContext(BetSlipContext);
  const [premiumDetail, setPremiumDetail] = useState([]);
  const [premiumDetail2, setPremiumDetail2] = useState({});
  const [position, setPosition] = useState([]);
  const [localLimit, setLocalLimit] = useState({});
  useEffect(() => {
    if (!parly && !isEmpty(user)) {
      getBetPosition(object);
    }
    if (!parly) {
      setRunner(object?.runners);
    }
  }, [parly, user]);

  useEffect(() => {
    let obj = profileData?.matchSetting?.find((res) => {
      return res?.type == "betFaire" && res?.sportType == object?.gameType;
    });
    if (!isEmpty(object?.betLimitSetting)) {
      setLocalLimit(
        !isEmpty(obj)
          ? {
              ...obj,
              minAmount: object?.betLimitSetting?.minBet,
              maxAmount: object?.betLimitSetting?.maxBet,
            }
          : {}
      );
    } else if (!isEmpty(profileData)) {
      setLocalLimit(!isEmpty(obj) ? obj : {});
    }
  }, [profileData, object]);

  let betCheckObj = {
    4: "cricket",
    2: "tennis",
    1: "soccer",
    3: "Casino",
  };
  useEffect(() => {
    if (parly) {
      if (gameTab == "soccer") {
        let obj1 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              (res?.marketName.includes("1x2") ||
                res?.marketName.includes("1X2"))
            );
          }) || {};

        if (!isEmpty(obj1)) {
          setPremiumDetail(obj1);
        }
        let obj2 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName.includes("Total")
            );
          }) || {};
        if (!isEmpty(obj2)) {
          setPremiumDetail2(obj2);
        }
      } else if (gameTab == "tennis") {
        let obj1 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName?.includes("Winner")
            );
          }) || {};
        if (!isEmpty(obj1)) {
          setPremiumDetail(obj1);
        }
        let obj2 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              (res?.marketName.includes("1x2") ||
                res?.marketName == "Total games")
            );
          }) || {};
        if (!isEmpty(obj2)) {
          setPremiumDetail2(obj2);
        }
      } else {
        let obj1 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName?.includes("Winner")
            );
          }) || {};
        if (!isEmpty(obj1)) {
          setPremiumDetail(obj1);
        }
        let obj2 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName == "Total"
            );
          }) || {};

        if (!isEmpty(obj2)) {
          setPremiumDetail2(obj2);
        } else {
          let newObj = multiOddsPremium?.filter((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              !res?.marketName?.includes("Winner")
            );
          });
          if (newObj?.length > 0) {
            setPremiumDetail2(newObj[0]);
          }
        }
      }
    }
  }, [parly, multiOddsPremium, type]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    cssEase: "linear",
  };

  const getBetPosition = async (item) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?marketId=${item?.marketId}&eventId=${item?.eventId}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        setPosition(response_users?.results);
        setRunner((current) =>
          response_users?.results?.betFair?.length > 0
            ? current.map((res) => {
                let obj = response_users?.results?.betFair.find(
                  (item) => item?.selectionId == res?.SelectionId
                );
                return {
                  ...res,
                  position:
                    obj?.selectionId == res?.SelectionId ? obj?.position : 0,
                  newPosition: 0,
                };
              })
            : current.map((res) => {
                return {
                  ...res,
                  position: 0,
                  newPosition: 0,
                };
              })
        );
      }
    }
  };

  const handelChange = (odds, type, item, object, index) => {
    let limit = profileData?.defaultStakeLimit?.find((res) => {
      return (
        res?.betfairId == object.eventType &&
        res?.market == "Match Odds" &&
        inRange(matchOdds?.totalMatched, res?.min, res?.max)
      );
    });
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setScrollId(index);
        if (!isEmpty(limit)) {
          setLimit({ ...localLimit, maxAmount: limit?.stake });
        } else {
          setLimit(localLimit);
        }
        setShowBetDialog(true);
        setTimeLeft(40);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: item,
          betType: type,
          playType: "betFair",
          object: {
            ...item,
            ...odds,
            teamName: item?.RunnerName,
            eventId: object?.eventId,
            marketId: object?.marketId,
          },
        }));
      }
    } else {
      navigate("/login");
    }
  };

  const handelBetFairPositions = (slipObj) => {
    let selectionsTemp = [...runner];
    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let backProfit = Number(
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice)
        );
        let backLoss = Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        let backProfit = Number(betSlipObject?.bidPrice);
        let backLoss =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (showBetDialog && betSlipObject?.playType == "betFair") {
      let newArray = matchOdds?.rt;
      if (
        betSlipObject?.object?.marketId == object?.marketId &&
        newArray?.length > 0
      ) {
      
        setBetLoader((loaderPrev) => {
          if (!loaderPrev) {
            setBetSlipObject((prev) => {
              if (prev?.playType == "betFair") {
                if (newArray?.length > 0) {

                  let updated = newArray?.find((res) => {
                    return (
                      res?.ib == prev?.object?.ib &&
                      res?.ri == prev?.object?.ri
                    );
                  });
                
                  handelBetFairPositions({
                    ...prev,
                    odds: updated?.rt,
                  });
                  return {
                    ...prev,
                    odds: updated?.rt,
                  };
                } else {
                  // resetPosition("betFair");
                  setShowBetDialog(false);
                  return {
                    odds: "",
                    bidPrice: 0,
                    teamName: "",
                    betType: "",
                  };
                }
              } else {
                return prev;
              }
            });
          }
          return loaderPrev;
        });
      }
    }
  }, [matchOdds, showBetDialog]);

  useEffect(() => {
    if (betPlace?.isPlaced && betPlace?.type == "matched") {
      getBetPosition(object);
    } else if (betPlace?.isPlaced && betPlace?.type == "error") {
      setResetBetPosition(false);
      resetBetSlipObject();
      setRunner((prev) => {
        return prev.map((res) => {
          return { ...res, newPosition: 0 };
        });
      });
    }
  }, [betPlace]);

  useEffect(() => {
    if (resetBetPosition) {
      setResetBetPosition(false);
      resetBetSlipObject();
      setRunner((prev) => {
        return prev.map((res) => {
          return { ...res, newPosition: 0 };
        });
      });
    }
  }, [resetBetPosition]);

  useEffect(() => {
    if (showBetDialog) {
      if (!isEmpty(betSlipObject)) {
        if (betSlipObject?.playType == "betFair") {
          handelBetFairPositions(betSlipObject, runner);
        }
      }
    }
  }, [betSlipObject, showBetDialog]);

  const handelPaly = (item, res) => {
    // if (res?.odds >= 1.7) {
    setParlyBet((current) => {
      let newObj = {
        ...item,
        sportsBookSelectionObject: res,
        selectObj: res?.id,
        newEventId: object?.eventId,
        eventName: object?.eventName,
        marketId: object?.marketId,
      };
      if (current?.length > 0) {
        if (
          current?.filter((temp) => {
            return temp?.newEventId == object?.eventId;
          })?.length > 0
        ) {
          if (
            current?.filter((temp) => {
              return temp?.newEventId != object?.eventId;
            })?.length > 0
          ) {
            secureLocalStorage.setItem("parlyBet", [
              ...current?.filter((temp) => {
                return temp?.newEventId != object?.eventId;
              }),
              newObj,
            ]);
            return [
              ...current?.filter((temp) => {
                return temp?.newEventId != object?.eventId;
              }),
              newObj,
            ];
          } else {
            secureLocalStorage.setItem("parlyBet", [newObj]);
            return [newObj];
          }
        } else {
          secureLocalStorage.setItem("parlyBet", [...current, newObj]);
          return [...current, newObj];
        }
      } else {
        secureLocalStorage.setItem("parlyBet", [newObj]);
        return [newObj];
      }
    });
    // } else {
    //   setMessage({
    //     ...message,
    //     status: true,
    //     message: "Min Odds Alowed 1.7",
    //     errorType: "Error",
    //   });
    // }
  };

  let scoreNew = score?.find((res) => {
    return Number(res?.eventId) == Number(object?.eventId);
  });

  return (
    <div className="sport-accord-body">
      <div className="position-relative">
        {parly ? (
          !isEmpty(premiumDetail) ? (
            <div
              className="d-flex justify-content-between flex-column align-items-center"
              style={{ background: "#fff", padding: `0.3rem` }}
            >
              <div className="w-100 d-flex align-items-center position-relative">
                <div
                  style={{
                    height: "23px",
                    background: "#ffc800",
                    width: "50%",
                    borderTopLeftRadius: "4px",
                  }}
                  className="parly-section-header"
                ></div>
                <div
                  style={{
                    height: "23px",
                    background: "#ffc800",
                    width: "50%",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                ></div>
              </div>
              <div className="d-flex w-100 h-100">
                <div
                  onClick={(e) => {
                    setScoreDetail(scoreNew?.score || {});
                  }}
                  style={{ background: "rgb(238 246 251/1)", height: "auto" }}
                  className="d-flex flex-column w-100 align-items-start p-2"
                >
                  <span
                    onClick={() => {
                      navigate(
                        `/full-market/${object?.marketId}/${object?.eventId}`
                      );
                    }}
                  >
                    {object?.eventName}
                    <br />{" "}
                    {score?.length > 0 && !isEmpty(scoreNew) ? (
                      <div className="counter">
                        {["soccer", "tennis"].includes(object?.gameType)
                          ? scoreNew?.score?.replace(/\s*-\s*/g, " : ").trim()
                          : cricketScore(scoreNew?.score)}
                      </div>
                    ) : object?.outcome && object?.outcome != undefined ? (
                      <div className="counter">
                        {cricketScore(object?.outcome?.trim())}
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                  <div
                    style={{ background: "none", paddingBottom: "10px" }}
                    className="d-flex align-items-end justify-content-between w-100 h-100"
                  >
                    <div
                      onClick={() => {
                        navigate(
                          `/full-market/${object?.marketId}/${object?.eventId}`
                        );
                      }}
                      className="icon-sports d-flex align-items-center"
                    >
                      <img
                        src="../assets/images/casino-icon/youtube.png"
                        alt=""
                      />
                      <span
                        className={`in-play ms-1 ${
                          keyTime == "in_play"
                            ? "in-play"
                            : keyTime == "today"
                            ? keyTime
                            : "tommorow"
                        }`}
                      >
                        {keyTime == "in_play"
                          ? "In-play"
                          : keyTime == "today"
                          ? `${obj?.matchDateTime(
                              object?.eventDateTime,
                              "same"
                            )}`
                          : obj?.dateFormat(object?.eventDateTime)}
                      </span>
                    </div>{" "}
                    {showScore?.includes(object?.scoreId) ? (
                      <i
                        class="icon-h2h-soild"
                        onClick={(e) => {
                          if (showScore?.includes(object?.scoreId)) {
                            setShowScore((prev) => {
                              return prev.filter((item) => {
                                return item !== object?.scoreId;
                              });
                            });
                          } else {
                            setTimer(true);
                            if (object?.scoreId !== "0") {
                              setShowScore((prev) => {
                                return [...prev, object?.scoreId];
                              });
                            }
                          }
                        }}
                      ></i>
                    ) : (
                      <i
                        class="icon-h2h"
                        onClick={(e) => {
                          if (showScore?.includes(object?.scoreId)) {
                            setShowScore((prev) => {
                              return prev.filter((item) => {
                                return item !== object?.scoreId;
                              });
                            });
                          } else {
                            setTimer(true);
                            if (object?.scoreId !== "0") {
                              setShowScore((prev) => {
                                return [...prev, object?.scoreId];
                              });
                            }
                          }
                        }}
                      ></i>
                    )}
                  </div>
                </div>{" "}
                <div
                  style={{
                    background: "rgb(238 246 251/1)",
                    marginTop: "-22.4px",
                    paddingBottom: "16px",
                    borderTopRightRadius: "4px",
                  }}
                  className="d-flex flex-column w-50 h-100 align-items-start"
                >
                  <Slider className="w-100 h-100 parly-slider" {...settings}>
                    <div>
                      <div className="parly-list-page-outer">
                        <span
                          style={{
                            fontSize: ".7142857142857143rem",
                            width: "50%",
                          }}
                          className="d-flex justify-content-center"
                        >
                          {object?.gameType == "soccer" ? "1x2" : "Winner"}
                        </span>{" "}
                        <span
                          style={{
                            fontSize: ".7142857142857143rem",
                            width: "50%",
                            // paddingTop: "10px",
                          }}
                          className="d-flex justify-content-center"
                        >
                          O/U
                        </span>
                      </div>

                      <div className="parly-list-page">
                        <div
                          className={
                            !isEmpty(premiumDetail) &&
                            premiumDetail?.marketName?.includes("1x2")
                              ? "parly-list-page-inner parly-soccer"
                              : "parly-list-page-inner"
                          }
                        >
                          {!isEmpty(premiumDetail) &&
                          (premiumDetail?.apiSiteStatus == "SUSPENDED" ||
                            premiumDetail?.marketStatus != 1) ? (
                            <span className="suspended">
                              <FaLock />
                            </span>
                          ) : (
                            ""
                          )}
                          {premiumDetail?.sportsBookSelection?.length > 0 ? (
                            <>
                              {premiumDetail?.sportsBookSelection
                                ?.slice(0, 3)
                                ?.map((res) => {
                                  let obj = parlyBet?.find((item) => {
                                    return (
                                      item?.marketName ==
                                        premiumDetail?.marketName &&
                                      item?.sportsBookSelectionObject?.odds ==
                                        res?.odds
                                    );
                                  });
                                  let oldObject =
                                    premiumDetail?.oldSportsBookSelection?.find(
                                      (obj) => {
                                        return obj?.id == res?.id;
                                      }
                                    ) || {};
                                  return (
                                    <SportAccordianInnerSlip
                                      premiumDetail={premiumDetail}
                                      handelPaly={handelPaly}
                                      obj={obj}
                                      res={res}
                                      oldObject={oldObject}
                                    />
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <div>
                                <span></span>
                                <strong></strong>
                              </div>
                              <div>
                                <span></span>
                                <strong></strong>
                              </div>
                            </>
                          )}{" "}
                        </div>
                        <div
                          className={
                            !isEmpty(premiumDetail2) &&
                            premiumDetail2?.marketName?.includes("1x2")
                              ? "parly-list-page-inner parly-soccer"
                              : "parly-list-page-inner"
                          }
                        >
                          {!isEmpty(premiumDetail2) &&
                          (premiumDetail2?.apiSiteStatus == "SUSPENDED" ||
                            premiumDetail2?.marketStatus != 1) ? (
                            <span className="suspended">
                              <FaLock />
                            </span>
                          ) : (
                            ""
                          )}
                          {premiumDetail2?.sportsBookSelection?.length > 0 ? (
                            <>
                              {premiumDetail2?.sportsBookSelection
                                ?.slice(0, 3)
                                ?.map((res) => {
                                  let obj = parlyBet?.find((item) => {
                                    return (
                                      item?.marketName ==
                                        premiumDetail2?.marketName &&
                                      item?.sportsBookSelectionObject?.odds ==
                                        res?.odds
                                    );
                                  });
                                  let oldObject =
                                    premiumDetail2?.oldSportsBookSelection?.find(
                                      (obj) => {
                                        return obj?.id == res?.id;
                                      }
                                    ) || {};
                                  return (
                                    <SportAccordianInnerSlip
                                      premiumDetail={premiumDetail2}
                                      handelPaly={handelPaly}
                                      obj={obj}
                                      res={res}
                                      oldObject={oldObject}
                                    />
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <div>
                                <span></span>
                                <strong></strong>
                              </div>
                              <div>
                                <span></span>
                                <strong></strong>
                              </div>
                            </>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="parly-list-page-outer">
                        <span
                          style={{
                            fontSize: ".7142857142857143rem",
                            width: "50%",
                            // paddingTop: "10px",
                          }}
                          className="d-flex justify-content-center"
                        >
                          Away Inn O/U
                        </span>{" "}
                        <span
                          style={{
                            fontSize: ".7142857142857143rem",
                            width: "50%",
                            // paddingTop: "10px",
                          }}
                          className="d-flex justify-content-center"
                        >
                          Total 6 O/U
                        </span>
                      </div>
                      <div className="parly-list-page">
                        {/* {premiumDetail[2]?.sportsBookSelection?.length > 0 ? (
                          premiumDetail[2]?.sportsBookSelection
                            ?.slice(0, 2)
                            ?.map((res) => {
                              let obj = parlyBet?.find((item) => {
                                return (
                                  item?.marketName ==
                                    premiumDetail[2]?.marketName &&
                                  item?.sportsBookSelectionObject?.odds ==
                                    res?.odds
                                );
                              });
                              return (
                                <div
                                  onClick={() =>
                                    handelPaly(premiumDetail[2], res)
                                  }
                                  className={
                                    !isEmpty(obj) &&
                                    obj?.sportsBookSelectionObject
                                      ?.selectionName == res?.selectionName &&
                                    obj?.sportsBookSelectionObject?.odds ==
                                      res?.odds
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <span>{res?.selectionName}</span>
                                  <strong>{res?.odds}</strong>
                                </div>
                              );
                            })
                        ) : ( */}
                        <>
                          <div>
                            <span></span>
                            <strong></strong>
                          </div>
                          <div>
                            <span></span>
                            <strong></strong>
                          </div>
                        </>
                        {/* // )} */}
                        {/* {premiumDetail[3]?.sportsBookSelection?.length > 0 ? (
                          premiumDetail[3]?.sportsBookSelection
                            ?.slice(0, 2)
                            ?.map((res) => {
                              let obj = parlyBet?.find((item) => {
                                return (
                                  item?.marketName ==
                                    premiumDetail[3]?.marketName &&
                                  item?.sportsBookSelectionObject?.odds ==
                                    res?.odds
                                );
                              });
                              return (
                                <div
                                  onClick={() =>
                                    handelPaly(premiumDetail[3], res)
                                  }
                                  className={
                                    !isEmpty(obj) &&
                                    obj?.sportsBookSelectionObject
                                      ?.selectionName == res?.selectionName &&
                                    obj?.sportsBookSelectionObject?.odds ==
                                      res?.odds
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <span>{res?.selectionName}</span>
                                  <strong>{res?.odds}</strong>
                                </div>
                              );
                            })
                        ) : ( */}
                        <>
                          <div>
                            <span></span>
                            <strong></strong>
                          </div>
                          <div>
                            <span></span>
                            <strong></strong>
                          </div>
                        </>
                        {/* // )} */}
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ background: "white" }}
            >
              <div
                onClick={() => {
                  setScoreDetail(
                    score?.find((res) => {
                      return Number(res?.eventId) == Number(object?.eventId);
                    })?.score || {}
                  );
                  navigate(
                    `/full-market/${object?.marketId}/${object?.eventId}`
                  );
                }}
                style={{
                  width:
                    (score?.length > 0 && !isEmpty(scoreNew)) ||
                    object?.outcome != undefined
                      ? "60%"
                      : "85%",
                }}
                className={parly ? "ps-1" : "ps-4"}
              >
                <div className="icon-sports d-flex align-items-center">
                  <div>
                    {!object?.eventName.includes(" SRL") && (
                      <img
                        src="../assets/images/casino-icon/youtube.png"
                        alt=""
                      />
                    )}
                  </div>
                  <span
                    className={`in-play ms-1 ${
                      keyTime == "in_play"
                        ? "in-play"
                        : keyTime == "today"
                        ? keyTime
                        : "tommorow"
                    }`}
                  >
                    {keyTime == "in_play"
                      ? "In-play"
                      : keyTime == "today"
                      ? `${obj?.matchDateTime(object?.eventDateTime, "same")}`
                      : obj?.dateFormat(object?.eventDateTime)}
                  </span>
                </div>
                <span>{object?.eventName}</span>
              </div>

              <div className="d-flex align-items-center gap-12">
                {score?.length > 0 && !isEmpty(scoreNew) ? (
                  <div className="counter">
                    {["soccer", "tennis"].includes(object?.gameType)
                      ? scoreNew?.score?.replace(/\s*-\s*/g, " : ").trim()
                      : cricketScore(scoreNew?.score)}
                  </div>
                ) : object?.outcome && object?.outcome != undefined ? (
                  <div className="counter">
                    {cricketScore(object?.outcome?.trim())}
                  </div>
                ) : (
                  ""
                )}
                {showScore?.includes(object?.scoreId) ? (
                  <i
                    class="icon-h2h-soild"
                    onClick={(e) => {
                      if (showScore?.includes(object?.scoreId)) {
                        setShowScore((prev) => {
                          return prev.filter((item) => {
                            return item !== object?.scoreId;
                          });
                        });
                      } else {
                        setTimer(true);
                        if (object?.scoreId !== "0") {
                          setShowScore((prev) => {
                            return [...prev, object?.scoreId];
                          });
                        }
                      }
                    }}
                  ></i>
                ) : (
                  <i
                    class="icon-h2h"
                    onClick={(e) => {
                      if (showScore?.includes(object?.scoreId)) {
                        setShowScore((prev) => {
                          return prev.filter((item) => {
                            return item !== object?.scoreId;
                          });
                        });
                      } else {
                        setTimer(true);
                        if (object?.scoreId !== "0") {
                          setShowScore((prev) => {
                            return [...prev, object?.scoreId];
                          });
                        }
                      }
                    }}
                  ></i>
                )}
                <i
                  class="icon-arrow-down -rotate-90 text-black-500"
                  onClick={() => {
                    navigate(
                      `/full-market/${object?.marketId}/${object?.eventId}`
                    );
                  }}
                ></i>
              </div>
            </div>
          )
        ) : (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ background: "white" }}
          >
            {!parly && (
              <div
                className={`fave  bg-star-sprit-black  position-absolute start-0 ${
                  activeFav?.includes(object?.eventId) ? "animate" : ""
                }`}
                style={{
                  marginLeft: "-27px",
                  marginTop: "-16px",
                }}
                onClick={() => {
                  if (isEmpty(user)) {
                    navigate("/login");
                  } else {
                    if (object?.hasMatchOdds) {
                      addToFavioute(
                        object?.eventId,
                        "Match Odds",
                        object?.marketId,
                        object?.eventType
                      );
                    }
                  }
                }}
              ></div>
            )}
            <div
              onClick={() => {
                setScoreDetail(
                  score?.find((res) => {
                    return Number(res?.eventId) == Number(object?.eventId);
                  })?.score || {}
                );
                navigate(`/full-market/${object?.marketId}/${object?.eventId}`);
              }}
              style={{
                width:
                  (score?.length > 0 && !isEmpty(scoreNew)) ||
                  object?.outcome != undefined
                    ? "60%"
                    : "85%",
              }}
              className={parly ? "ps-1" : "ps-4"}
            >
              <div className="icon-sports d-flex align-items-center">
                <div>
                  <i className="icon-virtual icon" />
                  {!object?.eventName.includes(" SRL") &&
                    Number(object?.channel) > 0 && (
                      <img
                        src="../assets/images/casino-icon/youtube.png"
                        alt=""
                      />
                    )}
                  {!parly && (
                    <>
                      {!object?.eventName.includes(" SRL") && (
                        <>
                          {object?.hasFancy && (
                            <img
                              src="../assets/images/casino-icon/f.png"
                              alt=""
                            />
                          )}
                          {object?.hasBookmaker && (
                            <img
                              src="../assets/images/casino-icon/b.png"
                              alt=""
                            />
                          )}
                        </>
                      )}

                      {(object?.eventType == 4 || object?.hasSportBook) && (
                        <img src="../assets/images/casino-icon/s.png" alt="" />
                      )}

                      {object?.eventName.includes(" SRL") && (
                        <svg
                          width="100%"
                          height="12px"
                          viewBox="0 0 34 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_3432_39516)">
                            <path
                              d="M9.165 0C9.625 0 10 0.374167 10 0.835V9.165C10 9.625 9.62583 10 9.165 10H0.835C0.725346 10 0.616766 9.9784 0.515459 9.93644C0.414152 9.89448 0.322103 9.83297 0.244566 9.75543C0.167029 9.6779 0.105523 9.58585 0.0635606 9.48454C0.0215979 9.38323 0 9.27465 0 9.165L0 0.835C0 0.375 0.374167 0 0.835 0H9.165Z"
                              fill="#D15BE4"
                            ></path>
                            <g clip-path="url(#clip1_3432_39516)">
                              <path
                                d="M8.571 3.76974L9.00005 2.54541H3.04486C2.74453 2.54541 2.47852 2.72396 2.38413 2.99603L1.28577 6.09086H3.39668L3.57688 5.58923H7.4469L7.8073 4.56045H3.93728L4.22046 3.76974H8.571Z"
                                fill="white"
                              ></path>
                              <path
                                d="M3.26438 6.36353H1.16192L1.03425 6.69686C0.889542 7.06807 1.22151 7.45443 1.68967 7.45443H7.14583L7.57143 6.36353H3.25587H3.26438Z"
                                fill="white"
                              ></path>
                            </g>
                            <path
                              d="M29.16 7.5001V3.1986H30.0805V7.5001H29.16ZM27.886 3.7936V3.0271H31.3475V3.7936H27.886Z"
                              fill="#D15BE4"
                            ></path>
                            <path
                              d="M26.598 7.5001L26.4965 6.6286C26.4801 6.47227 26.4475 6.3416 26.3985 6.2366C26.3495 6.1316 26.276 6.05227 26.178 5.9986C26.08 5.9426 25.9446 5.9146 25.772 5.9146L24.6905 5.9111V5.2041L25.828 5.2006C26.0823 5.2006 26.2643 5.14227 26.374 5.0256C26.486 4.9066 26.542 4.73743 26.542 4.5181V4.4341C26.542 4.2171 26.486 4.0526 26.374 3.9406C26.2643 3.82627 26.08 3.7691 25.821 3.7691H24.68V3.0271H25.9505C26.4755 3.0271 26.8581 3.13327 27.0985 3.3456C27.3411 3.55793 27.4625 3.8601 27.4625 4.2521V4.3501C27.4625 4.6441 27.3913 4.87743 27.249 5.0501C27.1066 5.22277 26.8803 5.32543 26.57 5.3581V5.4841L26.3145 5.3581C26.5805 5.37677 26.7858 5.43277 26.9305 5.5261C27.0775 5.61943 27.1848 5.74893 27.2525 5.9146C27.3201 6.07793 27.3715 6.28093 27.4065 6.5236L27.543 7.5001H26.598ZM24.0535 7.5001V3.0271H24.9495V5.4561L24.9565 5.7956V7.5001H24.0535Z"
                              fill="#D15BE4"
                            ></path>
                            <path
                              d="M21.4314 7.60162C20.8131 7.60162 20.3453 7.44062 20.0279 7.11862C19.7129 6.79428 19.5554 6.33112 19.5554 5.72912V4.81912C19.5554 4.21478 19.7129 3.74928 20.0279 3.42262C20.3453 3.09595 20.8131 2.93262 21.4314 2.93262C22.0498 2.93262 22.5164 3.09595 22.8314 3.42262C23.1488 3.74928 23.3074 4.21478 23.3074 4.81912V5.72912C23.3074 6.33112 23.1499 6.79428 22.8349 7.11862C22.5199 7.44062 22.0521 7.60162 21.4314 7.60162ZM21.4314 6.83862C21.7464 6.83862 21.9821 6.74528 22.1384 6.55862C22.2948 6.37195 22.3729 6.10945 22.3729 5.77112V4.77712C22.3729 4.42712 22.2948 4.15878 22.1384 3.97212C21.9821 3.78312 21.7464 3.68862 21.4314 3.68862C21.1188 3.68862 20.8843 3.78312 20.7279 3.97212C20.5716 4.15878 20.4934 4.42712 20.4934 4.77712V5.77112C20.4934 6.10945 20.5716 6.37195 20.7279 6.55862C20.8843 6.74528 21.1188 6.83862 21.4314 6.83862Z"
                              fill="#D15BE4"
                            ></path>
                            <path
                              d="M16.2441 6.1561V5.4071H17.3851C17.6371 5.4071 17.8191 5.34877 17.9311 5.2321C18.0454 5.1131 18.1026 4.9416 18.1026 4.7176V4.4621C18.1026 4.23343 18.0466 4.06193 17.9346 3.9476C17.8226 3.83093 17.6406 3.7726 17.3886 3.7726H16.2441V3.0271H17.4411C17.9848 3.0271 18.3826 3.1496 18.6346 3.3946C18.8889 3.6396 19.0161 3.98727 19.0161 4.4376V4.7456C19.0161 5.19593 18.8889 5.5436 18.6346 5.7886C18.3826 6.0336 17.9848 6.1561 17.4411 6.1561H16.2441ZM15.6316 7.5001V3.0271H16.5346V5.6206L16.5381 6.0371V7.5001H15.6316Z"
                              fill="#D15BE4"
                            ></path>
                            <path
                              d="M13.2396 7.59104C13.032 7.59104 12.8371 7.57587 12.6551 7.54554C12.4755 7.5152 12.3121 7.47787 12.1651 7.43354C12.0205 7.38687 11.8956 7.3402 11.7906 7.29354L11.7101 6.42554C11.8921 6.5212 12.108 6.60754 12.3576 6.68454C12.6073 6.7592 12.8733 6.79654 13.1556 6.79654C13.4543 6.79654 13.6701 6.75337 13.8031 6.66704C13.9361 6.5807 14.0026 6.4477 14.0026 6.26804V6.22954C14.0026 6.11287 13.9735 6.0172 13.9151 5.94254C13.8591 5.86554 13.7646 5.79787 13.6316 5.73954C13.4986 5.6812 13.3178 5.6217 13.0891 5.56104C12.7671 5.47237 12.5023 5.37204 12.2946 5.26004C12.0893 5.1457 11.9376 5.00454 11.8396 4.83654C11.7416 4.6662 11.6926 4.4527 11.6926 4.19604V4.15404C11.6926 3.76204 11.8245 3.46104 12.0881 3.25103C12.3541 3.04104 12.7461 2.93604 13.2641 2.93604C13.5861 2.93604 13.8626 2.9652 14.0936 3.02354C14.3246 3.08187 14.5183 3.14954 14.6746 3.22654L14.7551 4.03504C14.5801 3.9417 14.3795 3.8647 14.1531 3.80404C13.9291 3.74104 13.6841 3.70954 13.4181 3.70954C13.2198 3.70954 13.0623 3.7282 12.9456 3.76554C12.8313 3.80287 12.7485 3.8577 12.6971 3.93003C12.6481 4.00237 12.6236 4.08987 12.6236 4.19254V4.20304C12.6236 4.30804 12.6493 4.39904 12.7006 4.47604C12.7543 4.55304 12.8488 4.6242 12.9841 4.68954C13.1195 4.75254 13.312 4.81787 13.5616 4.88554C13.879 4.9742 14.138 5.06987 14.3386 5.17254C14.5416 5.2752 14.6921 5.4082 14.7901 5.57154C14.8881 5.73487 14.9371 5.9507 14.9371 6.21904V6.28204C14.9371 6.7137 14.796 7.04037 14.5136 7.26204C14.2336 7.48137 13.809 7.59104 13.2396 7.59104Z"
                              fill="#D15BE4"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1 1H10H33V9H10H1V1ZM0 0H1H33H34V1V9V10H33H1H0V9V1V0Z"
                              fill="#D15BE4"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_3432_39516">
                              <rect
                                width="34"
                                height="10"
                                rx="1"
                                fill="white"
                              ></rect>
                            </clipPath>
                            <clipPath id="clip1_3432_39516">
                              <rect
                                width="8"
                                height="6"
                                fill="white"
                                transform="translate(1 2)"
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                    </>
                  )}
                </div>
                <span
                  className={`in-play ms-1 ${
                    keyTime == "in_play"
                      ? "in-play"
                      : keyTime == "today"
                      ? keyTime
                      : "tommorow"
                  }`}
                >
                  {keyTime == "in_play"
                    ? "In-play"
                    : keyTime == "today"
                    ? `${obj?.matchDateTime(object?.eventDateTime, "same")}`
                    : obj?.dateFormat(object?.eventDateTime)}
                </span>
              </div>
              <span>{object?.eventName}</span>
            </div>

            <div className="d-flex align-items-center gap-12">
              {score?.length > 0 && !isEmpty(scoreNew) ? (
                <div className="counter">
                  {["soccer", "tennis"].includes(object?.gameType)
                    ? scoreNew?.score?.replace(/\s*-\s*/g, " : ").trim()
                    : cricketScore(scoreNew?.score)}
                </div>
              ) : object?.outcome && object?.outcome != undefined ? (
                <div className="counter">
                  {cricketScore(object?.outcome?.trim())}
                </div>
              ) : (
                ""
              )}
              {showScore?.includes(object?.scoreId) ? (
                <i
                  class="icon-h2h-soild"
                  onClick={(e) => {
                    if (showScore?.includes(object?.scoreId)) {
                      setShowScore((prev) => {
                        return prev.filter((item) => {
                          return item !== object?.scoreId;
                        });
                      });
                    } else {
                      setTimer(true);
                      if (object?.scoreId !== "0") {
                        setShowScore((prev) => {
                          return [...prev, object?.scoreId];
                        });
                      }
                    }
                  }}
                ></i>
              ) : (
                <i
                  class="icon-h2h"
                  onClick={(e) => {
                    if (showScore?.includes(object?.scoreId)) {
                      setShowScore((prev) => {
                        return prev.filter((item) => {
                          return item !== object?.scoreId;
                        });
                      });
                    } else {
                      setTimer(true);
                      if (object?.scoreId !== "0") {
                        setShowScore((prev) => {
                          return [...prev, object?.scoreId];
                        });
                      }
                    }
                  }}
                ></i>
              )}
              <i
                class="icon-arrow-down -rotate-90 text-black-500"
                onClick={() => {
                  navigate(
                    `/full-market/${object?.marketId}/${object?.eventId}`
                  );
                }}
              ></i>
            </div>
          </div>
        )}

        {runner?.length > 0 &&
          !parly &&
          !isEmpty(matchOdds) &&
          object?.marketId == matchOdds?.mi && (
            // object?.gameType !=="tennis" &&
            <div className="sports-team-table">
              <div className="match-odd-table bg-white p-3 px-0 py-1">
                <div
                  onClick={() => {
                    navigate(
                      `/full-market/${object?.marketId}/${object?.eventId}`
                    );
                  }}
                  className="d-flex back-lay-head justify-content-end ms-auto pb-1 bg-yellow w-100 mb-1"
                >
                  <div
                    className="half-table"
                    style={{
                      display: "flex",
                      // flex: "1",
                      justifyContent: "start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <h4 className="text-left h4-sport">Match Odds</h4>
                    <span>Matched</span>
                    <strong className="px-1">
                      {matchOdds?.totalMatched > 0 &&
                        `${priceFormat(matchOdds?.totalMatched)}`}
                    </strong>
                    {/* {matchOdds?.totalMatched < 10000 && (
                      <strong className="bg-danger rounded text-white low-button">
                        Low
                      </strong>
                    )} */}
                  </div>
                  <span className="text-center sport-header-inner">Back</span>
                  <span className="text-center sport-header-inner">Lay</span>
                </div>
                <ul className="mb-2 rounded position-relative p-0">
                  {runner?.map((newObj, index) => {
                    const odds_back = matchOdds?.rt
                      ?.filter((rt) => {
                        return rt.ib;
                      })
                      ?.filter((todd) => todd.ri == newObj.SelectionId);
                    const odds_lay = matchOdds?.rt
                      ?.filter((rt) => {
                        return !rt.ib;
                      })
                      ?.filter((todd) => todd.ri == newObj.SelectionId);
                    const odds_lay2 =
                      odds_lay?.find(
                        (todd) => todd.ri === newObj.SelectionId
                      ) || {};
                    let odds_back2 =
                      odds_back?.find(
                        (todd) => todd.ri === newObj.SelectionId
                      ) || {};
                    let layRt = odds_lay2?.rt > 0 ? odds_lay2?.rt : 0;
                    let backRt = odds_back2?.rt > 0 ? odds_back2?.rt : 0;
                    let disable = false;
                    if (odds_lay?.length == 0 && odds_back?.length == 0) {
                      disable = true;
                    } else if (layRt <= 0 && backRt <= 0) {
                      disable = true;
                    } else if (
                      (layRt > localLimit?.oddsLimit || layRt == 0) &&
                      (backRt > localLimit?.oddsLimit || backRt == 0)
                    ) {
                      disable = true;
                    } else {
                      disable = false;
                    }
                    return (
                      <li
                        style={{ scrollMarginTop: scrollHeight }}
                        ref={index == scrollId ? myRef : null}
                      >
                        <h2
                          onClick={() => {
                            navigate(
                              `/full-market/${object?.marketId}/${object?.eventId}`
                            );
                          }}
                        >
                          {newObj?.RunnerName}{" "}
                          {(position?.betFair?.length > 0 ||
                            Math.abs(newObj?.newPosition) > 0) && (
                            <span
                              className={`d-block py-1 ${
                                newObj?.position > 0
                                  ? "text-green team-red-score"
                                  : "team-red-score"
                              }`}
                            >
                              {position?.betFair?.length > 0 &&
                              isNumber(newObj?.position)
                                ? newObj?.position > 0
                                  ? Math?.abs(newObj?.position).toFixed(2)
                                  : `( ${Math.abs(newObj?.position).toFixed(
                                      2
                                    )} )`
                                : ""}
                              {object?.eventId ==
                                betSlipObject?.object?.eventId &&
                                Math.abs(newObj?.newPosition) >= 0 &&
                                showBetDialog && (
                                  <span
                                    className={
                                      newObj?.newPosition > 0
                                        ? "text-green"
                                        : "team-red-score"
                                    }
                                  >
                                    {" "}
                                    {">"}{" "}
                                    {newObj?.newPosition > 0
                                      ? Math.abs(newObj?.newPosition).toFixed(2)
                                      : `( ${Math.abs(
                                          newObj?.newPosition
                                        ).toFixed(2)} )`}
                                  </span>
                                )}
                            </span>
                          )}
                        </h2>
                        <BackLayHome
                          component={"betfair"}
                          type="sport"
                          index={index}
                          item={newObj}
                          object={object}
                          setSelect={setSelect}
                          handelChange={handelChange}
                          conditionNew={disable && matchOdds?.ms == 1}
                          condition={disable && matchOdds?.ms == 1 && !block}
                          odds_lay={odds_lay}
                          odds_back={odds_back}
                          ms={matchOdds?.ms}
                          limit={localLimit}
                        />
                        {/* {disable && matchOdds?.ms == 1 && !block && (
                          <div className="overlay-match">Suspended</div>
                        )} */}
                      </li>
                    );
                  })}

                  {(matchOdds?.ms != 1 ||
                    block ||
                    (!isEmpty(limitList) &&
                      matchOdds?.totalMatched < limitList?.limit)) && (
                    <div className="overlay-match">Suspended</div>
                  )}
                </ul>
              </div>
            </div>
          )}

        {showScore?.includes(object?.scoreId) && (
          <LiveMatchTracker
            scoreId={object?.scoreId}
            st={true}
            game={object?.gameType}
          />
        )}
      </div>
    </div>
  );
};

export default SportAccordianInner;
