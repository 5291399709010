import React, {
  useState,
  useEffect,
  useRef,
  startTransition,
  useContext,
} from "react";
import { Accordion } from "react-bootstrap";
import { FaArrowsAltV } from "react-icons/fa";
import { isEmpty, isNumber } from "lodash";
import { priceFormat } from "../Utils/constants";
import BackLay from "./BackLay";
import { useNavigate } from "react-router-dom";
import useScroll from "./Scroll";
import { AiOutlineStar } from "react-icons/ai";
import AuthContext from "../context/AuthContext";
const Bookmaker = ({
  backOdds,
  layOdds,
  bookmaker,
  user,
  profileData,
  detail,
  setBetSlipObject,
  setShowBetDialog,
  betSlipObject,
  setLimit,
  displayType,
  showBetDialog,
  scrollHeight,
  addToFavioute,
  ms,
  position,
}) => {
  const naviagte = useNavigate();
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [localLimit, setLocalLimit] = useState({});
  const { setTimeLeft } = useContext(AuthContext);
  useEffect(() => {
    let obj = profileData?.matchSetting?.find(
      (res) =>
        res?.type == "bookmaker" &&
        (profileData?.arrayCheck == "details"
          ? true
          : res?.sportType == detail?.gameType)
    );
    if (!isEmpty(obj)) {
      setLocalLimit(obj);
    } else {
      setLocalLimit({
        maxAmount: 1000,
        maxProfit: 10000,
        minAmount: 1,
        oddsLimit: 100,
      });
    }
  }, [profileData]);

  const handelChange = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      if (displayType == "bookmaker" ? odds?.rt > 0 : true) {
        setScrollId(index);
        setShowBetDialog(true);
        setTimeLeft(40);
        setLimit(localLimit);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: displayType == "bookmaker" ? odds?.rt : 0.95,
          teamName: team,
          betType: type,
          playType: displayType == "bookmaker" ? "bookmaker" : "toss",
          object: {
            ...item,
            ...odds,
            teamName: team,
            eventId: detail?.eventId,
            marketId: detail?.marketId,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };

  return (
    <div className="bookmaker p-2">
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div
              className="d-flex align-items-center"
              style={{ paddingLeft: "10px" }}
            >
              <div
                style={{ width: "3.8rem" }}
                onClick={() =>
                  addToFavioute(
                    detail?.eventId,
                    "Bookmaker",
                    detail?.bookmakerMarketId,
                    detail?.eventType
                  )
                }
                className={`fave  bg-star-sprit-black  start-0 ${
                  detail?.favourites?.find((obj) => {
                    return (
                      detail?.bookmakerMarketId == obj?.favoriteMarketId &&
                      obj?.favoriteType == "Bookmaker"
                    );
                  })
                    ? "animate"
                    : ""
                }`}
              ></div>
              {displayType == "bookmaker" ? "Bookmaker" : "Toss"}
            </div>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <div className="match-odd-table bg-white p-3 px-2 py-1 pe-0">
              <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
                <span className="text-center w-100">Back</span>
                {displayType == "bookmaker" && (
                  <span className="text-center w-100">Lay</span>
                )}
              </div>

              <ul className="mb-2 rounded position-relative p-0">
                {bookmaker?.map((item, index) => {
                  let odds_back = backOdds
                    ?.filter((todd) => todd?.runnerName == item?.RunnerName)
                    ?.sort((a, b) => {
                      return a - b;
                    });
                  const odds_lay = layOdds
                    ?.filter((todd) => todd?.runnerName == item?.RunnerName)
                    ?.sort((a, b) => {
                      return a - b;
                    });

                  const odds_lay2 =
                    layOdds?.find(
                      (todd) => todd?.runnerName == item?.RunnerName
                    ) || {};
                  let odds_back2 =
                    backOdds?.find(
                      (todd) => todd?.runnerName == item?.RunnerName
                    ) || {};
                  let layRt = odds_lay2?.rt > 0 ? odds_lay2?.rt : 0;
                  let backRt = odds_back2?.rt > 0 ? odds_back2?.rt : 0;
                  let disable = false;
                  if (odds_lay?.length == 0 && odds_back?.length == 0) {
                    disable = true;
                  } else if (layRt <= 0 && backRt <= 0) {
                    disable = true;
                  } else if (
                    (layRt > localLimit?.oddsLimit || layRt == 0) &&
                    (backRt > localLimit?.oddsLimit || backRt == 0)
                  ) {
                    disable = true;
                  } else {
                    disable = false;
                  }
                  return (
                    <li
                      ref={index == scrollId ? myRef : null}
                      style={{ scrollMarginTop: scrollHeight }}
                    >
                      <h2>
                        {item?.RunnerName}{" "}
                        {(position?.length > 0 ||
                          Math.abs(item?.newPosition) > 0) && (
                          <span
                            className={`d-block py-1 ${
                              item?.position > 0
                                ? "text-green team-red-score"
                                : "team-red-score"
                            }`}
                          >
                            {position?.length > 0 && isNumber(item?.position)
                              ? item?.position > 0
                                ? Math?.abs(item?.position).toFixed(2)
                                : `( ${Math.abs(item?.position).toFixed(2)} )`
                              : ""}
                            {Math.abs(item?.newPosition) >= 0 &&
                              showBetDialog && (
                                <span
                                  className={
                                    item?.newPosition > 0
                                      ? "text-green"
                                      : "team-red-score"
                                  }
                                >
                                  {" "}
                                  {">"}{" "}
                                  {item?.newPosition > 0
                                    ? Math.abs(item?.newPosition).toFixed(2)
                                    : `( ${Math.abs(item?.newPosition).toFixed(
                                        2
                                      )} )`}
                                </span>
                              )}
                          </span>
                        )}
                      </h2>
                      {displayType == "bookmaker" ? (
                        <BackLay
                          component={"bookmaker"}
                          index={index}
                          item={item}
                          conditionNew={disable && ms == 1}
                          handelChange={handelChange}
                          odds_lay={odds_lay}
                          odds_back={odds_back}
                          limit={localLimit}
                          condition={
                            disable &&
                            ms == 1 &&
                            (backOdds?.length !== 0 || layOdds?.length !== 0)
                          }
                          ms={ms}
                          detail={detail}
                        />
                      ) : (
                        <div className={`lay-back-table toss-back`}>
                          <div
                            onClick={() => {
                              handelChange(
                                1.95,
                                "back",
                                item?.RunnerName,
                                item,
                                index
                              );
                            }}
                            className={`back ${
                              betSlipObject?.teamName == item?.RunnerName &&
                              betSlipObject?.odds == 0.95 &&
                              betSlipObject?.playType == "toss" &&
                              showBetDialog
                                ? "back-active"
                                : ""
                            }`}
                          >
                            <span>
                              <strong>0.95</strong>
                            </span>
                          </div>
                        </div>
                      )}
                      {disable &&
                        ms == 1 &&
                        (backOdds?.length !== 0 || layOdds?.length !== 0) && (
                          <div className="overlay-match">Suspended</div>
                        )}
                    </li>
                  );
                })}
                {ms != 1 ||
                  (backOdds?.length == 0 && layOdds?.length == 0 && (
                    <div className="overlay-match">Suspended</div>
                  ))}
              </ul>
              <div className="market-depth-sec d-flex justify-content-end">
                <div className="d-flex align-items-center min-max">
                  <FaArrowsAltV />
                  <span>min/max</span>
                  <strong>
                    {localLimit?.minAmount || 1}/
                    {priceFormat(localLimit?.maxAmount) || 100}
                  </strong>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Bookmaker;
