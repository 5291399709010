import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
const SubCasino = ({ header, subCasino, setSubCasino }) => {
  const live = ["SEXY", "EVO", "PT", "PP", "HOTROAD"];
  const table = ["KINGMAKER", "NETENT", "JILI"];
  const slot = [
    "PP",
    "KINGMAKER",
    "DRAGOONSOFT",
    "NETENT",
    "SPADE",
    "PT",
    "JDB",
    "FC",
    "JILI",
  ];
  const fishing = ["SPADE", "JDB", "JILI"];
  const egame = ["SPRIBE", "PP", "JDB"];
  const awc = ["BG", "BTG", "NLC", "RT", "YesBingo"];    //remove E1SPORT,YL,SV388
  const obj = {
    live: live,
    slot: slot,
    table: table,
    fishing: fishing,
    egame: egame,
    AWC: awc,
  };
  return (
    <>
      <div className="d-flex align-items-center flex-wrap subcasino subcasino-new">
        {obj[header]?.map((res) => {
          return (
            <span
              style={{
                position: "relative",
              }}
              onClick={() => setSubCasino(res)}
              // className={subCasino === res && "active"}
            >
              <p
                style={{
                  position: "relative",
                  zIndex: "10",
                  color: subCasino === res ? "black" : 'white',
                  padding: "0",
                  margin: "0",
                  marginTop:"2px"
                }}
              >
                {res == "KINGMAKER" ? "KINGMIDAS" : res}
              </p>
              {subCasino === res && (
                <motion.div
                  layoutId="pill-tab-casino-top"
                  transition={{ type: "spring", duration: 0.5 }}
                  className="casino-header-slide"
                  // style={{
                  //   position: "absolute",
                  //   inset: "0px",
                  //   zIndex: "0",
                  //   background: "#ffc800",
                  //   color: "black",
                  //   // fontSize: ".9285714285714286rem",
                  //   // borderRadius: ".25rem",
                  //   // height:"1.7142857142857142rem",
                  //   // padding: "0 .4rem ",
                  //   // margin:".1rem .5rem",
                  //   // width:'100%'
                  // }}
                ></motion.div>
              )}
            </span>
          );
        })}
      </div>
    </>
  );
};

export default SubCasino;
