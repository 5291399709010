import React, { useContext, useEffect, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { IoChevronBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import LayoutComponent from "../components/shared/Layout";
import { IoIosArrowBack } from "react-icons/io";
import { useForm } from "react-hook-form";
import { preventMaxInput, validationRules } from "../Utils/constants";
import { isEmpty, pick } from "lodash";
import jwt_decode from "jwt-decode";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState({
    first: true,
    second: true,
    third: true,
  });
  const { logoutUser, user, profileData } = useContext(AuthContext);
  const navigate = useNavigate();

  const [changePass, setChangePass] = useState({});
  const handleShow = (id) => {
    setShowPassword({ ...showPassword, [id]: !showPassword[id] });
  };
  const [step, setStep] = useState(0);
  // console.log(changePass, "changePass");
  const [success, setSuccess] = useState({
    check: true,
    message: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
    watch,
    trigger,
    setValue,
  } = useForm({});

  useEffect(() => {
    if (!isEmpty(user)) {
      if (!user?.results?.isPasswordReset) {
        navigate("/change-password");
      }
    } else {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (!isEmpty(watch("confirmPassword"))) {
      if (watch("newPassword")) {
        trigger("confirmPassword");
      }
    }
  }, [watch("newPassword")]);

  const onSubmit = async (body) => {
    body.uniqueId = Math.random() * 10000;
    const obj = pick(body, ["oldPassword", "newPassword", "uniqueId"]);
    const { status, data } = await apiPost(apiPath.changePassword, obj);
    if (status === 200) {
      if (data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.setItem("token", data.results.token);
        localStorage.setItem("refresh_token", data.results.refresh_token);
        navigate("/");
      } else {
        setSuccess({ check: true, message: data?.message });
      }
    } else {
      setSuccess({ check: true, message: data?.message });
    }
  };

  return (
    <div>
      {step == 0 ? (
        <LayoutComponent page={"change"}>
          <div className="d-flex align-items-center justify-content-start">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                background: "white",
                borderRadius: "50%",
                marginLeft: "8px",
                marginTop: "8px",
              }}
              onClick={() => {
                logoutUser();
              }}
            >
              <IoIosArrowBack size={30} className="fs-1 p-1 cursor-pointer" />
            </div>
          </div>
          <div className="login-log-sec w-100">
            <div className="login-logo">
              <img src="../assets/images/velki-logo.png" alt="" />
            </div>
            <div className="px-3">
              <img
                src="../assets/images/velki-login-signup-banner.png"
                alt=""
              />
            </div>
            <Form
              onSubmit={handleSubmit((e) => {
                // console.log(e, profileData, "eee");
                if (profileData?.pwd == e?.oldPassword) {
                  setStep(1);
                  setChangePass(e);
                } else {
                  setSuccess({
                    check: true,
                    message: "Your Password is incorrect",
                  });
                }
              })}
              className="bg-white p-4"
            >
              <label className="mb-3 d-block text-center fs-2">
                Change Password
              </label>

              <Form.Group className="mb-3">
                <FloatingLabel
                  controlId="floatingPassword"
                  label="New Password"
                >
                  <Form.Control
                    type={!showPassword?.second ? "text" : "password"}
                    placeholder="New Password"
                    maxLength={16}
                    minLength={8}
                    onInput={(e) => preventMaxInput(e)}
                    {...register("newPassword", {
                      required: "Please enter new password.",
                      validate: (value) => {
                        if (value === "") {
                          return true;
                        }
                        if (!!value.trim()) {
                          return true;
                        } else {
                          return "White spaces not allowed.";
                        }
                      },
                      // pattern: {
                      //   value: validationRules.password,
                      //   message:
                      //     "New Password must contain lowercase,uppercase characters, numbers, special character and must be 8 character long.",
                      // },
                    })}
                  />
                  <FaLock className="position-absolute translate-middle-y top-50" />
                  <Button
                    className="position-absolute translate-middle-y top-50 end-0 me-3 bg-white border-0 text-dark p-0 "
                    onClick={() => handleShow("second")}
                  >
                    {!showPassword?.second ? (
                      <FaEye className="opacity-100 fs-4" />
                    ) : (
                      <FaEyeSlash className="opacity-100 fs-4" />
                    )}
                  </Button>
                </FloatingLabel>
                {errors.newPassword && errors?.newPassword?.message && (
                  <label className="text-danger">
                    {errors.newPassword.message}
                  </label>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel
                  controlId="floatingPassword"
                  label="New Password Confirm"
                >
                  <Form.Control
                    type={!showPassword?.third ? "text" : "password"}
                    placeholder="New Password Confirm"
                    onInput={(e) => preventMaxInput(e)}
                    maxLength={16}
                    minLength={8}
                    {...register("confirmPassword", {
                      required: {
                        value: true,
                        message: "Please enter new password confirm.",
                      },
                      validate: (value) => {
                        if (value === "") {
                          return true;
                        }
                        if (!isEmpty(watch("newPassword"))) {
                          if (value == watch("newPassword")) {
                            return true;
                          } else {
                            return "Password and confirm password does not match.";
                          }
                        }
                      },
                    })}
                    onChange={(e) => {
                      setValue("confirmPassword", e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <FaLock className="position-absolute translate-middle-y top-50" />
                  <Button
                    className="position-absolute translate-middle-y top-50 end-0 me-3 bg-white border-0 text-dark p-0 "
                    onClick={() => handleShow("third")}
                  >
                    {!showPassword?.third ? (
                      <FaEye className="opacity-100 fs-4" />
                    ) : (
                      <FaEyeSlash className="opacity-100 fs-4" />
                    )}
                  </Button>
                </FloatingLabel>
                {errors.confirmPassword && errors?.confirmPassword?.message && (
                  <label className="text-danger">
                    {errors.confirmPassword.message}
                  </label>
                )}
              </Form.Group>
              <Form.Group className="mb-1">
                <FloatingLabel
                  controlId="floatingPassword"
                  label="Your Password"
                >
                  <Form.Control
                    type={!showPassword?.first ? "text" : "password"}
                    placeholder="Your Password"
                    maxLength={16}
                    minLength={8}
                    onInput={(e) => preventMaxInput(e)}
                    {...register("oldPassword", {
                      required: "Please enter your password.",
                      validate: (value) => {
                        if (value === "") {
                          return true;
                        }
                        if (!!value.trim()) {
                          return true;
                        } else {
                          return "White space are not allowed";
                        }
                      },
                    })}
                  />
                  <FaLock className="position-absolute translate-middle-y top-50" />
                  <Button
                    className="position-absolute translate-middle-y top-50 end-0 me-3 bg-white border-0 text-dark p-0 "
                    onClick={() => handleShow("first")}
                  >
                    {!showPassword?.first ? (
                      <FaEye className="opacity-100 fs-4" />
                    ) : (
                      <FaEyeSlash className="opacity-100 fs-4" />
                    )}
                  </Button>
                </FloatingLabel>
                {errors.oldPassword && errors?.oldPassword?.message && (
                  <label className="text-danger">
                    {errors.oldPassword.message}
                  </label>
                )}
              </Form.Group>
              {success?.check && (
                <label className="text-danger">{success?.message}</label>
              )}
              <div className="text-center">
                <Button type="submit" className="theme-btn ms-auto px-5">
                  Change
                </Button>
              </div>

              <ul
                style={{ fontSize: ".8571428571428571rem" }}
                className="list-outside p-0 m-0 mt-4 pe-0 px-4"
              >
                <li>
                  Password must have 8 to 15 alphanumeric without white space
                </li>
                <li>Password cannot be the same as username / nickname</li>
                <li>
                  Must contain at least 1 capital letter, 1 small letter and 1
                  number
                </li>
              </ul>
            </Form>
          </div>
        </LayoutComponent>
      ) : step == 1 ? (
        <div className="first-step">
          <div className="modal-title flex items-center justify-center h-40px text-pop-title">
            <h2>Announcement</h2>
            <span className="icon-icon-close text-white absolute right-4 text-base"></span>
          </div>
          <div className="modal-content">
            <h1>AGE RESTRICTION DISCLAIMER</h1>
            <div className="title-divide"></div>
            <div>
              <p>To play at this site, your age should be 18 years or above.</p>
              <p>
                {" "}
                You are required to truthfully state your age while registering.
                Minors and underage are strictly prohibited to participate in
                any activity on this site.{" "}
              </p>
              <p>
                {" "}
                Your account with this site may stand suspended till
                satisfactory documentation to prove your age is provided to us.
                If we find later that you are underage or minor we reserve the
                right to suspend or deactivate your account whichever we find
                appropriate.{" "}
              </p>
            </div>
          </div>
          <div className="first-step-content">
            <div className="step-check">
              <input
                id="checkbox_01"
                name="checkbox_01"
                type="checkbox"
                className="hidden"
              />
              <label for="checkbox_01">Do not show again. </label>
            </div>
            <div className="first-step-btn">
              <Button onClick={() => logoutUser()}>I am below 18</Button>
              <Button onClick={() => setStep(2)}>I am 18 or above</Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="first-step">
          <div className="modal-title flex items-center justify-center h-40px text-pop-title">
            <h2>Announcement</h2>
            <span className="icon-icon-close text-white absolute right-4 text-base"></span>
          </div>
          <div className="modal-content">
            <h1>Exchange Rules and Regulations</h1>

            <div className="title-divide"></div>

            <div>
              <div className="pop-page-full__box">
                <h2>Player security</h2>
                <p>
                  {" "}
                  All VELKI Sub account holders are advised to read the
                  following ‘Terms and Conditions’. All users who use VELKI
                  agree and accept to the following:{" "}
                </p>
                <ol>
                  <li>
                    {" "}
                    Any form of ‘Passing of funds’, ‘Self Matching’ will not be
                    tolerated on VELKI. Any users found participating in such
                    activities will be locked with the funds being reversed.
                    Accounts participating in such activities must note that
                    VELKI reserves the right to Void any bets of such nature at
                    any time within 1 week of the bet being placed.{" "}
                  </li>
                  <li>
                    {" "}
                    Any self matching ( punching ) bets on non favorite teams
                    when liquidity is low will be voided even if the player
                    account is in minus for the event , the upline will be
                    responsible for the same.{" "}
                  </li>
                  <li>
                    {" "}
                    Please note that if any account has been locked due to
                    ‘Passing of funds’ within the last 72 hours, VELKI reserves
                    the right to void any bet of this nature within the account
                    irrespective of when the bet was placed.{" "}
                  </li>
                  <li>
                    {" "}
                    No Arguments or claim in the above context will be
                    entertained by VELKI and the decision made by VELKI will
                    stand as final.{" "}
                  </li>
                  <li>
                    VELKI Endeavors to have our services run 24 hours a day.
                    However due to any technical issue, or disruption of
                    services from our provider (Betfair.com), VELKI will not be
                    liable for any market positions that any account holders may
                    hold.{" "}
                  </li>
                  <li>
                    {" "}
                    Betfair.com reserves the right to resettle or void any
                    market for reasons such as technical delayed suspension from
                    the provider. In any such case VELKI will settle according
                    to Betfair.com{" "}
                  </li>
                  <li>
                    {" "}
                    Account holders will be held responsible for the financial
                    penalties incurred for any misuse or ‘passing of funds’ from
                    any of the ‘Sub’ account holders under their accounts
                    irregardless whether or not the financial positions of the
                    sub accounts have been settled by their superior holders.{" "}
                  </li>
                  <li>
                    In case anyone is found using 2 different IDs and logging in
                    from same IP his winning in both accounts will be cancelled.
                  </li>
                  <li>
                    {" "}
                    Any bets which are deemed of being suspicious, including
                    bets which have been placed from the stadium or from a
                    source at the stadium maybe voided at anytime. The decision
                    of whether to void the particular bet in question or to void
                    the entire market will remain at the discretion of VELKI.
                    The final decision of whether bets are suspicious will be
                    taken by VELKI and that decision will be full and final.{" "}
                  </li>
                  <li>
                    {" "}
                    Any sort of cheating bet, any sort of Matching (Passing of
                    funds), Court Siding, Sharpening, Commission making is not
                    allowed in VELKI, If any VELKI User is caught in any of such
                    act then all the funds belonging that account would be
                    seized and confiscated. No argument or claim in that context
                    would be entertained and the decision made by VELKI
                    management will stand as final authority.{" "}
                  </li>
                  <li>
                    {" "}
                    Fluke hunting/Seeking is prohibited in VELKI, All the fluke
                    bets will be reversed. Cricket commentary is just an
                    additional feature and facility for VELKI user but VELKI is
                    not responsible for any delay or mistake in commentary.{" "}
                  </li>
                  <li>
                    {" "}
                    Members are not permitted to hold multiple accounts. This
                    includes holding an account with any associated site
                    operating on the same platform as this site. Where maximum
                    bet or maximum market limits are imposed, the Site reserves
                    to the right to void bets from accounts found to be
                    exceeding these limits by using multiple accounts across
                    this and any other associated sites.{" "}
                  </li>
                </ol>
                <br />
                <h2>Exchange Rules and Regulations</h2>
                <h2>Part A - Introduction</h2>
                <h2>Restricted territory information</h2>
                <p>
                  {" "}
                  Please be advised that our restricted territory information
                  pertains to both residents and visitors in the restricted
                  areas. Accounts registered in a non-restricted territory will
                  become restricted if they are attempt to access and have
                  betting activity from a territory which is restricted.
                  <br />
                  <br /> Restricted territory countries are Algeria, Australia,
                  Austria, Bulgaria, Canada, China, Cyprus, Czech Republic,
                  Democratic People’s Republic of Korea, Denmark, Europe, France
                  (and French territories), Gibraltar, Germany, Iran, Iraq,
                  Ireland, Italy, Japan, Malta, New Zealand, Poland, Portugal,
                  Qatar, Romania, Singapore, South Africa, Spain, Turkey, United
                  Kingdom and United States of America (and US territories)
                  <br /> We have the right to void all players winnings if they
                  are deemed to come from a restricted territory.{" "}
                </p>
                <br />
                <h2>1. Use and interpretation</h2>
                <p>
                  The Exchange Rules and Regulations ("Exchange Rules") are part
                  of terms and conditions.
                </p>
                <p>
                  {" "}
                  The Exchange Rules apply to all bets placed on Exchange
                  markets. The Exchange Rules also apply to Exchange ’Multiples’
                  product (see the Multiples section below for further details).{" "}
                </p>
                <p>The Exchange Rules consist of the following:</p>
                <ol>
                  <li>This INTRODUCTION section (Part A);</li>
                  <li>The GENERAL RULES (set out in Part B below);</li>
                  <li>
                    The SPECIFIC SPORTS RULES (set out in Part C below – these
                    apply to certain sports and to financial markets); and
                  </li>
                  <li>
                    The MARKET INFORMATION (located on every market either under
                    the tab entitled "Rules" or under the ‘Rules’).
                  </li>
                </ol>
                <p>
                  {" "}
                  The General Rules apply to all bets unless stated otherwise in
                  the Market Information or the Specific Sports Rules. If there
                  is any inconsistency between the Specific Sports Rules and the
                  General Rules, the Specific Sports Rules shall prevail. If
                  there is any inconsistency between the Market Information and
                  either the General Rules or the Specific Sports Rules, the
                  Market Information shall prevail, except where the General
                  Rules or Specific Sports Rules use the phrase 'regardless of
                  what it says in the Market Information' or similar wording.
                  <br />
                  <br /> For any category or market not referred to in the
                  Specific Sports Rules (e.g. ’Special Bets’ or beach
                  volleyball), the General Rules and Market Information will
                  apply.{" "}
                </p>
                <br />
                <h2>2. Market information</h2>
                <p>
                  {" "}
                  The Market Information is provided for information purposes as
                  an at-a-glance guide on how to manage the market. The Market
                  Information may also contain rules on market settlement,
                  however it must always be read in conjunction with the General
                  Rules and the relevant Specific Sports Rules and a link to
                  this Rules and Regulations page will usually be provided in
                  the Market Information for each market.
                  <br />
                  Whilst the Market Information may give a guide to how markets
                  will be managed, regardless of what it says in the Market
                  Information, it reserves the right to suspend any market at
                  any time at its sole discretion, including in order to carry
                  out necessary administration and/or to take any necessary
                  action to protect the customers.
                  <br />
                  It shall not amend the Market Information after a market has
                  been loaded except to correct obvious errors and/or to add
                  wording to clarify the Market Information where appropriate.{" "}
                </p>
                <br />
                <h2>3. Customer responsibility</h2>
                <p>
                  {" "}
                  The customers should make themselves aware of all the Exchange
                  Rules affecting any market on which they wish to place a bet.
                  Customers should not simply rely on the Market Information as
                  the Market Information is unlikely to contain all of the
                  applicable rules relating to a market.
                  <br />
                  By their nature ‘Special Bets’ markets are unpredictable so
                  should be treated with particular caution. Customers are
                  responsible for managing their own positions at all times in
                  such markets. Customers should pay particular attention to the
                  Market Information on ‘Special Bets’ markets to ensure they
                  understand the basis on which the market will be administered
                  and settled.{" "}
                </p>
                <br />
                <h2>4. Customer betting disputes &amp; IBAS</h2>
                <p>
                  {" "}
                  Any customer who has any concerns or questions regarding the
                  Exchange Rules or regarding the settlement of any market
                  should contact.
                  <br />
                  If a customer is not satisfied with how a bet or a market has
                  been settled then the customer should provide details of their
                  grievance.{" "}
                </p>
                <br />
                <h2>Part B - General rules</h2>
                <h2>1. Managing markets In-Play</h2>
                <h3>a) General</h3>
                <ul>
                  <li>
                    {" "}
                    For everything other than horseracing and greyhound racing,
                    if a market is not scheduled to be turned in-play but fails
                    to suspend the market at the relevant time, then:{" "}
                    <ul>
                      <li>
                        if the event has a scheduled 'off' time, all bets
                        matched after that scheduled off time will be void; and
                      </li>
                      <li>
                        {" "}
                        if the event does not have a scheduled 'off' time, it
                        will use its reasonable endeavours to ascertain the time
                        of the actual 'off' and all bets after the time of the
                        'off' determined will be void.{" "}
                      </li>
                    </ul>
                  </li>
                  <li>
                    {" "}
                    For horseracing and greyhound racing, if a market is not
                    scheduled to be turned in-play but fails to suspend the
                    market at the relevant time, then all bets matched after the
                    official 'off' time will be void.{" "}
                  </li>
                  <li>
                    {" "}
                    It aims to use its reasonable endeavours to suspend in-play
                    markets at the start of and at the end of the event.
                    However, regardless of what it says in the Market
                    Information, it does not guarantee that such markets will be
                    suspended at the relevant time.{" "}
                  </li>
                  <li>
                    It will not part-suspend outcomes/selections in an Exchange
                    market that has been turned in-play.
                  </li>
                  <li>
                    Customers are responsible for managing their in-play bets at
                    all times.
                  </li>
                  <li>
                    {" "}
                    For the purposes of in-play betting, customers should be
                    aware that transmissions described as "live" by some
                    broadcasters may be delayed or pre-recorded. The extent of
                    any delay may vary depending on the set-up through which
                    they are receiving pictures or data.{" "}
                  </li>
                </ul>
                <h3>
                  b) All markets other than soccer markets and Australian
                  markets - not suspending at the time of the 'off'
                </h3>
                <ul>
                  <li>
                    {" "}
                    In relation to markets which are scheduled to be turned
                    in-play, aims to use its reasonable endeavours to turn such
                    markets in-play at the time of the 'off'. The time of the
                    'off' for such markets should be set out in the Market
                    Information. However, regardless of what it says in the
                    Market Information, it does not guarantee that such markets
                    will be suspended and turned in-play at the time of the
                    'off'.{" "}
                  </li>
                  <li>
                    {" "}
                    If a market is scheduled to be turned in-play but does not
                    suspend the market and cancel unmatched bets at the time of
                    the 'off' and the market is not turned in-play with
                    unmatched bets cancelled at any time during the event, all
                    bets matched after the scheduled time of the 'off' will be
                    void (in the case of horseracing and greyhound racing, bets
                    will be void from the official rather than the scheduled
                    'off' time).{" "}
                  </li>
                  <li>
                    {" "}
                    If the event does not have a scheduled 'off' time, it will
                    use its reasonable endeavours to ascertain the time of the
                    actual 'off' and all bets after the time of the 'off'
                    determined will be void.{" "}
                  </li>
                  <li>
                    {" "}
                    If a market is scheduled to be turned in-play but does not
                    suspend the market at the time of the 'off' (so unmatched
                    bets are not cancelled at that time), but the market is
                    intentionally turned in-play at a later time during the
                    event, all bets matched after the time of the 'off' will
                    stand{" "}
                  </li>
                </ul>
                <h3>
                  {" "}
                  c) Soccer markets (with the exception of Australian soccer
                  markets) - not suspending at kick-off or on the occurrence of
                  a Material Event{" "}
                </h3>
                <p>Not suspending at kick-off</p>
                <ul>
                  <li>
                    {" "}
                    In relation to soccer markets that are scheduled to be
                    turned in-play, aims to use its reasonable endeavours to
                    turn such markets in-play at kick-off and to suspend such
                    markets on the occurrence of a Material Event (see
                    definition of "Material Event" below).{" "}
                  </li>
                  <li>
                    {" "}
                    The relevant scheduled kick-off time should be set out in
                    the Market Information. However, regardless of what it says
                    in the Market Information, it does not guarantee that such
                    markets will be suspended and turned in-play at kick-off.{" "}
                  </li>
                  <li>
                    {" "}
                    If a market is scheduled to be turned in-play but not
                    suspend the market at kick-off and the market is not turned
                    in-play at any time during the match, all bets matched after
                    the scheduled time of the kick-off will be void.{" "}
                  </li>
                  <li>
                    {" "}
                    If a market is scheduled to be turned in-play but not
                    suspend the market at kick-off (so unmatched bets are not
                    cancelled at that time), but the market is turned in-play at
                    a later time during the match, all bets matched after the
                    scheduled time of the kick-off and before the first
                    "Material Event" will stand. However, if there has been one
                    or more "Material Events", any bets matched between the
                    first "Material Event" and the market being turned in-play
                    will be void.{" "}
                    <p>Not suspending on the occurrence of a Material Event</p>
                  </li>
                  <li>
                    {" "}
                    If it does not suspend a market on time for the occurrence
                    of a Material Event, it reserves the right to void bets
                    unfairly matched after the Material Event has occurred.
                    Voiding of these bets may take place during the event or
                    retrospectively once a game is completed.{" "}
                  </li>
                  <p>Definition of “Material Event”</p>
                  <li>
                    {" "}
                    For the purpose of these Exchange Rules, a "Material Event"
                    shall mean a goal being scored, a penalty being awarded or a
                    player being sent off.{" "}
                  </li>
                </ul>
                <h3>d) In-play Australian markets</h3>
                <ul>
                  <li>
                    {" "}
                    Notwithstanding the other in-play rules described above, in
                    relation specifically to any Australian market that is
                    scheduled to be turned in-play, if it fails to suspend the
                    market at the 'off' then all bets matched after the
                    scheduled time of the 'off' and before the market is turned
                    in-play will be void (in the case of horseracing and
                    greyhound racing, bets will be void from the official rather
                    than the scheduled ‘off’ time). If the event does not have a
                    scheduled 'off' time, it will use its reasonable endeavours
                    to ascertain the time of the actual 'off' and all bets after
                    the time of the 'off' as determined will be void.{" "}
                  </li>
                </ul>
                <br />
                <h2>2. Results and market settlement</h2>
                <h3>a) General</h3>
                <ul>
                  <li>
                    Markets will be settled as set out in the Market Information
                    and/or the Specific Sports Rules.
                  </li>
                  <li>
                    {" "}
                    Where the Market Information or Specific Sports Rules do not
                    specify how and on what basis a market will be settled,
                    markets will be settled on the official result of the
                    relevant governing body regardless of any subsequent
                    disqualification or amendment to the result (except if an
                    amendment is announced within 24 hours of the initial
                    settlement of the relevant market in order to correct an
                    error in reporting the result).{" "}
                  </li>
                  <li>
                    {" "}
                    If no official result of a relevant governing body is
                    available, the result will be determined by using
                    information from independent sources. In such cases, if any
                    new information comes into the public domain within 48 hours
                    of settlement, then it shall (acting reasonably) determine
                    either: (i) whether the market should be reinstated or
                    resettled in light of this new information; or (ii) whether
                    or not to wait for further information before deciding
                    whether to reinstate or resettle the market. Except it has
                    announced that it is waiting for further information, any
                    information that comes into the public domain more than 48
                    hours after a market has been settled shall not be
                    considered (regardless of whether or not such information
                    may have led to a different result).{" "}
                  </li>
                  <li>
                    {" "}
                    In the event of any uncertainty about any result or
                    potential result, it reserves the right to suspend
                    settlement of any market for an unlimited period until the
                    uncertainty can be resolved to the reasonable satisfaction.
                    It reserves the right to void any market if the uncertainty
                    regarding settlement cannot be resolved to reasonable
                    satisfaction.{" "}
                  </li>
                </ul>
                <h3>b) Resettlements</h3>
                <ul>
                  <li>
                    {" "}
                    Markets are generally settled shortly after the end of the
                    event in question. It may settle (or part-settle) some
                    markets before the official result is declared (or may
                    increase a customer's 'available to bet' balance by the
                    minimum potential winnings of that customer on a given
                    market) purely as a customer service benefit. However, it
                    reserves the right to amend the settlement of the market if:
                    (i) the official result is different to the result on which
                    initially settled the market (for example, a horseracing
                    result being changed by the relevant governing body shortly
                    after a race, but before the result is official); or (ii) if
                    the whole market is eventually voided (e.g. for an abandoned
                    event).{" "}
                  </li>
                  <li>
                    {" "}
                    It reserves the right to reverse the settlement of a market
                    if a market is settled in error (for example, a human or
                    technical error).{" "}
                  </li>
                  <li>
                    If resettles a market, this may lead to amendments being
                    made to a customer's balance to reflect changes in market
                    settlement.
                  </li>
                </ul>
                <h3>c) Non-runners, withdrawals and disqualifications</h3>
                <ul>
                  <li>
                    {" "}
                    Subject always right to void bets under its terms and
                    conditions or for any exception under the Exchange Rules, if
                    a market contains a statement that says, "All bets stand,
                    run or not" (or something similar) in the Market
                    Information, then all bets on a team or competitor will
                    stand regardless of whether or not the team or competitor
                    starts the event or takes any part in the event.{" "}
                  </li>
                  <li>
                    {" "}
                    Where the Market Information does not stipulate that all
                    bets will stand regardless of participation, customers
                    should refer to the relevant Specific Sports Rules.{" "}
                  </li>
                  <li>
                    {" "}
                    If a team or competitor is disqualified, withdraws or
                    forfeits after starting an event they will be deemed a loser
                    providing at least one other team or competitor completes
                    the event. If no team or competitor completes an event
                    (having started) then all bets will be void except for bets
                    on any markets which have been unconditionally determined.{" "}
                  </li>
                </ul>
                <h3>d) Winner with [named selection]' markets</h3>
                <ul>
                  <li>
                    {" "}
                    It may from time to time offer markets that are dependent on
                    the participation of a particular competitor. If the
                    competitor named either in a 'Winner with …' market title or
                    in the Market Information does not participate in the
                    tournament or event then all bets on the market will be
                    void. For example, if there was a "Winner with Federer"
                    tennis market, all bets on the market would be void if
                    Federer did not participate in the tournament. However, if
                    any other competitor did not participate, then bets would
                    stand.{" "}
                  </li>
                  <li>
                    {" "}
                    A team or competitor will be deemed to have participated if
                    they have taken part to the extent necessary to record an
                    official result or classification (including any
                    disqualification but excluding any "did not start" or
                    equivalent classification).{" "}
                  </li>
                </ul>
                <br />
                <h2>3. Abandonments, Cancellations, Postponements</h2>
                <ul>
                  <li>
                    {" "}
                    Some markets have different rules and these are listed in
                    the Specific Sports Rules and/or the Market Information.
                    However, where a market has no rules in the Specific Sports
                    Rules or the Market Information in relation to an
                    abandonment, cancellation and/or postponement the following
                    shall apply.{" "}
                  </li>
                  <li>
                    {" "}
                    In relation to any match, fixture, game, individual event,
                    race or similar: If the event is not completed within three
                    days after the scheduled completion date, then all bets on
                    markets for this event will be void, except for bets on any
                    markets that have been unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    In relation to any tournament, competition or similar: If
                    the event is not completed within three days after the
                    scheduled completion date, then any markets relating to the
                    event will be settled in accordance with the official ruling
                    of the relevant governing body, providing such a decision is
                    given within 90 days after the scheduled completion date. If
                    no official ruling is announced in this 90-day period, then
                    bets on any market relating to this event will be void,
                    except for bets on any markets which have been
                    unconditionally determined. If a market is to be voided but
                    has been part-settled as a courtesy to customers, then such
                    part-settled bets will be reversed and all bets on the
                    market will be void.{" "}
                  </li>
                  <li>
                    {" "}
                    It will decide (acting reasonably) whether a market relates
                    to a match (or similar) or a tournament (or similar).
                    However, by way of example, the following shall apply: (i)
                    Europa League outright = tournament; (ii) Champions’ League
                    Group outright = tournament; (iii) Top Premiership goal
                    scorer = tournament; (iv) 72-hole Golf Match bet = match;
                    (v) Ryder Cup outright = tournament; (vi) Golf tournament
                    outright = tournament; (vii) Tennis Tournament outright =
                    tournament;(viii) 5-day Cricket Test Match = match; (ix)
                    Ashes Series outright winner = tournament; (x) Motor Race
                    (e.g. Grand Prix) = match.{" "}
                  </li>
                </ul>
                <br />
                <h2>4. Change of venue</h2>
                <ul>
                  <li>
                    {" "}
                    Some markets have different rules and these are listed in
                    the Specific Sports Rules and/or the Market Information.
                    However, if change of venue is not dealt with in the
                    Specific Sports Rules and/or the Market Information then the
                    following shall apply:{" "}
                  </li>
                  <li>
                    {" "}
                    For any team sport: if the scheduled venue is changed after
                    the market is loaded, all bets will be void only if the new
                    venue is a home ground of the original away team{" "}
                  </li>
                  <li>
                    {" "}
                    For all categories or markets other than team sports: if the
                    scheduled venue is changed after the market is loaded, all
                    bets will stand.{" "}
                  </li>
                  <li>
                    {" "}
                    If there is a change in the type of scheduled surface (e.g.
                    a hockey match switching from grass to Astor-turf) after the
                    market has been loaded, all bets will stand.{" "}
                  </li>
                </ul>
                <br />
                <h2>5. Periods of time</h2>
                <ul>
                  <li>
                    {" "}
                    Some markets have different rules and these are listed in
                    the Specific Sports Rules and/or the Market Information.
                    However, if not dealt with in the Specific Sports Rules or
                    the Market Information then the following shall apply.{" "}
                  </li>
                  <li>
                    {" "}
                    If the scheduled duration of an event is changed after the
                    market has been loaded but before the start of the event,
                    then all bets will be void.{" "}
                  </li>
                  <li>
                    {" "}
                    Some markets refer to the length of time until an occurrence
                    in the event (e.g. time of first goal). If an event happens
                    in stoppage or injury time after any regular time period
                    then it will be deemed to have occurred at the end of the
                    regular time period. For example, if a goal is scored in
                    first half stoppage-time in a soccer match it will be deemed
                    to have occurred on 45 minutes.{" "}
                  </li>
                  <li>
                    {" "}
                    All bets apply to the relevant full 'regular time' period
                    including stoppage time. Any extra-time and/or penalty
                    shoot-out is not included.{" "}
                  </li>
                  <li>
                    {" "}
                    References within these Rules and Regulations to a
                    particular number of 'days' shall mean the end of the day
                    local time after the expiry of the specified number of days.
                    For example, if a rugby match is scheduled for the 1st of
                    December, then the rule that allows the match to be
                    completed within three days after the scheduled completion
                    date (see Paragraph 3 above) would mean that the deadline
                    for completion of that match would be 23.59:59 on the 4th of
                    December.{" "}
                  </li>
                </ul>
                <br />
                <h2>6. Match bets</h2>
                <ul>
                  <li>
                    {" "}
                    Some markets have different rules and these are listed in
                    the Specific Sports Rules and/or the Market Information. For
                    example, for match bets on Golf markets the rules are set
                    out in the Specific Sports Rules. However, if not dealt with
                    in the Specific Sports Rules or the Market Information then
                    the following shall apply.{" "}
                  </li>
                  <li>
                    {" "}
                    'Match Bets' for one-off events are determined by the
                    competitor or team with the best score, time or finishing
                    position in the event. If none of the competitors or teams
                    involved in the match bet complete the event or register a
                    score, time or finishing position then bets are void unless
                    specified otherwise in the Specific Sports Rules and/or the
                    Market Information. Any competitor or team not completing an
                    event or registering a score, time or finishing position
                    having taken part in the match bet event will be settled as
                    a loser providing at least one other team or competitor
                    completes that event or registers a score, time or finishing
                    position.{" "}
                  </li>
                  <li>
                    {" "}
                    'Match Bets' for progress in a competition or event with
                    multiple heats or rounds are determined by the competitor or
                    team which qualifies to the furthest round (whether it takes
                    part in the further round or not) or with the best score,
                    time or finishing position in the final or same heat of that
                    competition or event. If the relevant competitors or teams
                    fail to qualify in the same round of the competition but in
                    different heats then dead-heat rules will apply,
                    irrespective of the finishing positions in their respective
                    heats. Markets will be part-settled after the end of each
                    round and any subsequent disqualifications, penalties or
                    amendments to results or qualifications will not have any
                    effect on the market. Where one or more of the competitors
                    or teams are disqualified, for the purposes of settlement,
                    the disqualified competitor or team will be deemed to have
                    progressed further in the competition or event than all
                    those eliminated from the competition or event prior to the
                    disqualification, and will be deemed to have finished last
                    (or joint last if there is more than one disqualification)
                    of those still competing in the competition or event.
                    Disqualification will be considered to have taken place at
                    the time of the competitor's or team's removal from the
                    competition or event by the relevant governing body, rather
                    than at the time of the event which caused disqualification.{" "}
                  </li>
                  <li>
                    If one of the competitors or teams does not take any part in
                    the event, then all relevant match bets will be void.
                  </li>
                  <li>
                    {" "}
                    If an event or tournament is abandoned or reduced in
                    duration such that any competitor or team fails to complete
                    the match bet, event or tournament for any reason other than
                    withdrawal or disqualification then all bets will be void
                    except for those on markets which have been unconditionally
                    determined.{" "}
                  </li>
                </ul>
                <br />
                <h2>7. "To qualify" markets</h2>
                <ul>
                  <li>
                    {" "}
                    Some markets have different rules and these are listed in
                    the Specific Sports Rules and/or the Market Information.
                    However, if not dealt with in the Specific Sports Rules or
                    the Market Information then the following shall apply.{" "}
                  </li>
                  <li>
                    {" "}
                    Any 'to qualify' market (e.g. "to reach the final" markets)
                    will be determined by the competitor or team that qualifies
                    under the terms set out in Market Information, whether or
                    not they take part in the next round or event for which they
                    have qualified. Markets will be settled after the qualifying
                    stage and any subsequent disqualification or amendment to
                    the result will not count.{" "}
                  </li>
                </ul>
                <br />
                <h2>8. Dead heats</h2>
                <ul>
                  <li>
                    {" "}
                    Unless stated otherwise in the Specific Sports Rules and/or
                    the Market Information the Dead Heat Rule applies to bets on
                    a market where there are more winners than expected (as set
                    out in the Market Information).{" "}
                  </li>
                  <li>
                    {" "}
                    For each bet matched on a relevant winning selection, the
                    stake money is first reduced in proportion by multiplying it
                    by the sum of the number of winners expected (as set out in
                    the Market Information), divided by the number of actual
                    winners (i.e. stake multiplied by (number of winners
                    expected/number of actual winners)). The winnings are then
                    paid to the successful backers on this 'reduced stake'
                    (reduced stake multiplied by traded price) and the remaining
                    stake money is paid to the appropriate layers.{" "}
                  </li>
                  <li>
                    {" "}
                    For example, assume there is a dead heat for first place
                    between three horses. 'Client A' has backed one of the
                    winners for a stake of 300 at the traded price of 4.0 and
                    'Client B' has taken the other side of this bet. When the
                    event is settled, the stake (300) is multiplied by 1/3 (i.e.
                    the number of expected winners (1) divided by the number of
                    actual winners (3)) to calculate the reduced stake (100) and
                    the remainder given to the layer (200). The backer then
                    receives the traded price matched (4.0) multiplied by the
                    reduced stake (4 x 100 = 400). In this example, Client A's
                    net winnings are 100 (400 payout minus the original 300
                    stake), and Client B's net losses are 100. Alternatively,
                    this can be viewed as the full amount paid to the traded
                    price divided by the number of dead-heaters. In the above
                    example, this would be 300 at the traded price of 4.0 (1200)
                    divided by 3; making 400 payouts and 100 net winnings.{" "}
                  </li>
                  <li>
                    {" "}
                    By way of another example, assume there is an outright
                    winner in a golf tournament but 7 players tie for 2nd place.
                    In the "top 5 finish" market, after settling on the outright
                    winner in the top 5 market there would be 4 others
                    designated winner’s places available. 'Client A' has backed
                    one of the winners for 300 at the traded price of 4.0 and
                    'Client B' has taken the other side of this bet. When the
                    event is settled, the stake (300) is multiplied by 4/7 (i.e.
                    the number of expected winners (4) divided by the number of
                    actual winners (7)) to calculate the reduced stake (171.43)
                    and the remainder given to the layer (128.57). The backer
                    then receives the traded price matched (4.0) multiplied by
                    the reduced stake (4 x 171.43 = 685.72). In this example,
                    Client A's net winnings are 385.72 (685.72 payout minus the
                    original 300 stake), and Client B's net losses are 385.72.{" "}
                  </li>
                  <li>
                    {" "}
                    In relation to customers whose betting counterparty, for the
                    purposes of this section of the Exchange Rules dealing with
                    dead heats, the terms 'Client A', 'Client B' and
                    'appropriate layers' should, where relevant, be read{" "}
                  </li>
                </ul>
                <br />
                <h2>9. Exchange Multiples</h2>
                <ul>
                  <li>The Exchange Rules only apply to Exchange Multiples.</li>
                  <li>
                    Customers placing a multiple bet will be betting with each
                    other will act as the counterparty to the bet.
                  </li>
                  <li>
                    A multiple bet consists of a number of legs. A leg is
                    defined as one or more chosen selections in any individual
                    event market.
                  </li>
                  <li>
                    It reserves the right in its sole discretion not to accept
                    certain multiple bets or to scale back stakes in certain
                    circumstances.
                  </li>
                  <li>
                    {" "}
                    All multiple bets placed are subject to the Exchange Rules
                    that apply to each individual sport that relates to any leg
                    of any multiple bet.{" "}
                  </li>
                  <li>
                    The maximum payout limit for Exchange Multiples is
                    £1,000,000.
                  </li>
                  <li>
                    {" "}
                    Customers can place an Exchange Multiple bet using back, or
                    where available lay or a mixture of back and lay selections.
                    However, customers cannot have a combination of back and lay
                    selections in any one leg.{" "}
                  </li>
                  <li>
                    {" "}
                    If customers choose more than one selection in one leg the
                    odds for this leg will be "dutched" which means they will be
                    combined to reflect the chances of any of the selections
                    within the leg winning (if backed) or all of the selections
                    losing (if laid). If customers wish to place a
                    cross-multiple bet (i.e. more than one selection in any
                    event market but not using "dutched" prices) they will need
                    to enter each Exchange Multiple bet separately.{" "}
                  </li>
                  <li>
                    {" "}
                    Other than Exchange Multiples bets struck at Starting Price
                    ("BSP"), the odds available via the Exchange Multiples
                    product will be broadly based on the relevant singles
                    markets on the Exchange and any such winning multiple bet
                    will be subject to commission as set out in the Charges'
                    section of the website.{" "}
                  </li>
                  <li>
                    {" "}
                    Any winnings from Exchange Multiples bets struck at BSP will
                    not be subject to commission, but the odds returned on each
                    leg in such a multiple will be subject to a 5% deduction
                    from the BSP.{" "}
                  </li>
                  <li>
                    {" "}
                    A BSP each way Exchange Multiple bet is a bet for selections
                    in the multiple to win and a bet on the same selections to
                    place. For example, a £2 each way double represents a £2 bet
                    on both selections to win and a £2 bet on both selections to
                    be placed, with a total stake for the bet of £4. The number
                    of places for each event in each way multiples bet are shown
                    on the multiples win Market Information and do not change.
                    Should the number of runners be equal to or less than the
                    number of places available, the relevant place leg of any
                    multiples bet will be void{" "}
                  </li>
                  <li>
                    {" "}
                    The minimum total stake for any Exchange Multiple bet is £2.
                    For example, a 20p "Yankee" (11 bet combinations)
                    representing a total stake of £2.20 is permitted. However, a
                    1p "Heinz" (57 bet combinations) representing a total stake
                    of £0.57 or a £1 double (1 bet combination) representing a
                    total stake of £1 will not be permitted. For customers
                    betting in currencies other than English Sterling, the
                    minimum total stake which applies to an Exchange Multiple
                    bet will not necessarily be the equivalent of £2, though it
                    will be calculated as described in the example above.{" "}
                  </li>
                  <li>
                    {" "}
                    If, in its sole discretion, will determine the markets that
                    are available for Exchange Multiples. Events available for
                    Exchange Multiples will be those listed within any
                    individual Exchange Multiple group (e.g. UK football
                    fixtures for any given day). Not all markets will be
                    available via the Exchange Multiples product.{" "}
                  </li>
                  <li>
                    {" "}
                    With the exception of the rule directly below, if any
                    selection in any leg is a non-runner or otherwise void under
                    the Exchange Rules (e.g. an abandoned match) then all bets
                    on that individual leg will be void and the Exchange
                    Multiple bet shall be adjusted accordingly. For example, a
                    treble including one void leg will become a double. This
                    means that if customers have more than one selection in any
                    leg (i.e. in cases of 'ditching') and one of those
                    selections is a non-runner, the whole leg will be void. In
                    the event that voided legs mean that an individual bet
                    within a multiple becomes a single bet, then this single bet
                    will stand.{" "}
                  </li>
                  <li>
                    {" "}
                    Notwithstanding the rule directly above, for Exchange
                    Multiples bets struck at BSP, if customers have more than
                    one selection in any leg and one of those selections is a
                    non-runner the leg will stand. For example, if customers
                    back both Desert Orchid and Red Rum in an individual horse
                    race and Red Rum is a non-runner, the leg will become a back
                    of just Desert Orchid.{" "}
                  </li>
                  <li>
                    {" "}
                    It reserves the right not to accept certain combinations of
                    Exchange Multiples, such as those including related
                    contingencies (i.e. where the outcome of one event is likely
                    to affect the odds on the outcome of another event). This
                    may happen automatically at the bet placement stage.
                    Alternatively, if such bet is taken in error, it may void
                    the individual bet combinations which include two or more of
                    the related contingency selections.{" "}
                  </li>
                  <li>
                    {" "}
                    It may offer special markets on related events from time to
                    time, including within its normal markets (i.e. outside of
                    the Exchange Multiples product), for example a market on
                    Chelsea to win the English domestic double (i.e. Premiership
                    and FA Cup).{" "}
                  </li>
                  <li>
                    {" "}
                    When placing any Exchange Multiple bet, the prices shown
                    only give an indicative guide as to the price available for
                    each leg and the overall multiple. Other than Exchange
                    Multiples bets struck at BSP, the price of each leg and the
                    overall multiple price that customers get will be fixed when
                    the multiple bet is actually placed and customers will then
                    be able to see these prices. For more details on this
                    customer should refer to the 'help' files.{" "}
                  </li>
                  <li>
                    {" "}
                    Horseracing Exchange Multiples are based on ‘day of the
                    race’ markets (and not ante-post markets). Horseracing
                    ante-post rules do not therefore apply in relation to
                    horseracing Exchange Multiples.{" "}
                  </li>
                </ul>
                <br />
                <h2>10. Rules for Starting Price</h2>
                <ul>
                  <li>
                    {" "}
                    Starting Price ('SP') is available on the Exchange. Exchange
                    SP bets (‘SP bets’) are therefore a type of Exchange bet.
                    The SP is calculated as, by balancing all SP bets and other
                    Exchange bets when the market is suspended at the 'off' of
                    the relevant event. The details of this calculation are
                    provided directly below.{" "}
                  </li>
                  <li>
                    Please note that for all customers, an SP bet cannot be
                    cancelled once it has been placed.
                  </li>
                  <li>
                    {" "}
                    A bet at SP is a fixed odd bet, with the odds on each
                    selection being calculated and bets matched when the event
                    starts. The odds are calculated by matching SP backers and
                    other Exchange backers against SP layers and other Exchange
                    layers. The inclusion of other Exchange bets in the SP
                    reconciliation is necessary to ensure that: SP backers get
                    the benefit of unmatched Exchange offers to lay if those
                    offers could increase the SP; and SP layers get the benefit
                    of unmatched Exchange offers to back if those offers could
                    reduce the SP. Including unmatched other Exchange in the SP
                    reconciliation also ensures that the bets of other Exchange
                    backers and layers, which would otherwise lapse, are matched
                    where possible.{" "}
                  </li>
                  <li>
                    The minimum liability for a bet placed at SP is £10 for a
                    lay bet and £2 for a back bet (or the currency equivalent in
                    each case).
                  </li>
                  <li>
                    {" "}
                    Example 1: on selection A there is £1,000 of backers' stakes
                    and £6,000 of layers' liabilities at SP and there are £500
                    of unmatched other Exchange back bets available to lay at an
                    average of 5.0. If we ignored the Exchange market in this
                    case the SP would be 7.0. However, this would leave other
                    Exchange back bets, that could also have been matched at
                    their requested price against SP layers, unmatched.
                    Therefore, the SP will be 5.0 and all SP backers and SP
                    layers will be matched at that price. The £500 of Exchange
                    backers' stakes will also be matched at their requested
                    price of 5.0 against the SP layers.{" "}
                  </li>
                  <li>
                    {" "}
                    Example 2: on selection B there are £831 of backers' stakes
                    and £4,428 of layers' liabilities at SP and the following
                    unmatched other Exchange lay bets, available to back: £20 at
                    6.8, £31.13 at 6.6 and £100 at 6.4. In this case the SP will
                    be 6.68. This is calculated by including the £20 available
                    to back at 6.8 and the £31.13 available to back at 6.6 and
                    balancing those amounts against the SP backers' stakes and
                    SP layers' liabilities. The £100 available to back on the
                    Exchange at 6.4 remains unmatched as to include any of this
                    amount would lead to an imbalance between SP backers and SP
                    layers.{" "}
                  </li>
                  <li>
                    {" "}
                    The SP is calculated to six decimal places for each
                    selection, though it may be displayed in the relevant market
                    view (or in any form/results data) to two decimal places,
                    rounded up or down as applicable. After reconciliation, the
                    full SP on each selection is available by clicking on the
                    relevant individual runner graph.{" "}
                  </li>
                  <li>
                    {" "}
                    If for whatever reason the site is unavailable when an event
                    starts or the SP cannot otherwise be reconciled at the
                    'off’, it will determine the SP using all available
                    information. For the avoidance of doubt this information
                    will not be limited to betting activity on the relevant
                    market(s). Also in these circumstances, a licensed betting
                    operator within the group may act as risk counterparty to SP
                    bets if necessary to ensure a fair SP. The personnel
                    involved in determining the SP in such circumstances will
                    have no undeclared personal or other interest in the SP in
                    question.{" "}
                  </li>
                  <li>
                    {" "}
                    If the SP reconciliation process is undertaken prematurely
                    (for example if a horserace is turned in-play in error),
                    then it will endeavor to reverse the reconciliation so that
                    the SP is determined when the event starts. There may
                    however be circumstances in which this is not possible, in
                    which case the SP will be based on the initial
                    reconciliation.{" "}
                  </li>
                  <li>
                    {" "}
                    In cases where an SP reconciliation is reversed: SP bets
                    (including SP limit bets), 'At In-play: Take SP' bets and
                    'At In-play: Keep' bets will all revert to their status
                    before the reconciliation; and 'At In-play: Cancel' bets
                    will either remain cancelled if not matched as part of the
                    reconciliation process, or if matched as part of the
                    reconciliation process, will revert to their unmatched
                    status before the reconciliation.{" "}
                  </li>
                  <li>
                    {" "}
                    If the SP reconciliation process is undertaken later than
                    scheduled (i.e. after the event has started) and determines
                    that a material event has occurred (this will generally mean
                    that the event is not turned in-play), the SP will be
                    determined based solely on SP bets (and ‘At In-play: Take
                    SP’ bets which are unmatched when suspends the relevant
                    market), placed before the 'off'. This means that ‘At
                    In-play: Take SP’ bets matched after the off, will be made
                    void and not included in the reconciliation process. In
                    addition, SP bets placed after the 'off' will be voided.
                    However, if the SP reconciliation process is undertaken
                    later than scheduled but determines that no material event
                    has occurred, all bets will stand.{" "}
                  </li>
                  <li>
                    If a SP 'each way' option is offered this will be processed
                    as two separate bets: a win bet at SP and a 'to be placed'
                    bet at SP.
                  </li>
                  <li>
                    {" "}
                    If for any reason unmatched bets are cancelled prior to a
                    market going in-play, then any unmatched bets that have been
                    selected to 'At In-play: Take SP' will be converted to SP
                    bets. Once those bets are converted, they cannot be
                    cancelled.{" "}
                  </li>
                </ul>
                <p>➢ Party against whom you are betting at SP</p>
                <ul>
                  <li>
                    {" "}
                    When you place a bet at SP you are betting against other
                    customers. However, during the reconciliation of the SP, it
                    acts as counterparty in order to balance liabilities between
                    SP bets and other Exchange bets{" "}
                  </li>
                </ul>
                <p>➢ Placing a SP bet</p>
                <ul>
                  <li>
                    Selecting the 'SP' button in the market view gives customers
                    two different ways to request an SP bet. These are as
                    follows:
                  </li>
                  <li>
                    {" "}
                    The first way to request a bet at SP is by leaving the 'Set
                    SP odds limit' box unticked at the top right of the bet
                    manager. For a back bet you are required to enter the stake
                    you wish to bet on the selection. For a lay bet, you are
                    required to enter the liability you are prepared to risk
                    against the selection – in other words the amount that you
                    are prepared to lose, should the selection win (or be placed
                    as applicable).{" "}
                  </li>
                  <li>
                    {" "}
                    The second way to request a bet at SP is to select the 'Set
                    SP odds limit' option. Using this you can request a bet at
                    SP conditional upon minimum SP odds in the case of a back
                    bet, or maximum SP odds in the case of a lay bet. If SP is
                    shorter than the minimum price requested by a backer or is
                    longer than the maximum price requested by a layer, then the
                    relevant bet will lapse when the event starts. If SP is
                    longer than the minimum price requested by a backer or is
                    shorter than the maximum price requested by a layer, the bet
                    will be matched at the SP. Where an SP is equal to the price
                    limit specified by customers, those bets will be included
                    based on the time they were submitted, on a first come,
                    first served basis as is the existing convention for other
                    Exchange bets. This means that such bets may be unmatched or
                    partially matched.{" "}
                  </li>
                  <li>
                    {" "}
                    Please note that if a non-runner cannot be removed from the
                    relevant market until after the completion of the event, the
                    application to all bets matched at or before the "off"
                    (including SP bets) of any reduction factor, may mean that
                    the matched price for an SP back bet with an odds limit
                    requested, is adjusted to a price below the lower limit
                    requested. It may also mean that an SP lay bet request with
                    maximum odds requested, may not be matched even though the
                    odds on the selection, after any post-race adjustment for
                    the late non-runner, is below the maximum odds requested.{" "}
                  </li>
                  <li>
                    {" "}
                    If an SP bet with an odds limit is requested, the
                    minimum/maximum odds requested can be shortened in the case
                    of a back bet or a lengthened in the case of a lay bet at
                    any time before the event starts. However, as mentioned
                    above, an SP bet request cannot actually be cancelled by a
                    customer once it has been placed. Details of the impact of
                    non-runners on the SP re provided below.{" "}
                  </li>
                </ul>
                <p>➢ Exchange bets which are unmatched at the 'off'</p>
                <ul>
                  <li>
                    {" "}
                    A normal Exchange bet is placed by choosing the odds of your
                    selection from the market view (as opposed to clicking on
                    the 'SP' of your selection). When such an Exchange bet is
                    fully or partially unmatched it can be adjusted and
                    cancelled in the normal course. Previously, unmatched bets
                    were automatically cancelled when a market was suspended at
                    the 'off' of the relevant event. You can now choose to
                    either have your unmatched Exchange bet convert to an SP bet
                    when the market suspends at the start of the event, or to
                    have the bet 'persist' when the event goes in-play (see the
                    'keep' option below).{" "}
                  </li>
                  <li>
                    {" "}
                    In order to convert your unmatched Exchange bet to an SP bet
                    when the market suspends you should select the 'At In-Play:
                    Take SP' option in the bet manager. If there is a non-runner
                    in a win market with a reduction factor of at least 2.5%, or
                    a non-runner in a place market with a reduction factor of at
                    least 4%, it is policy to cancel unmatched lay bets on all
                    other runners in the market. In the case of any such
                    non-runner, instead of being cancelled a lay bet for which
                    the 'At In-Play: Take SP' option has been selected, will
                    automatically convert to an SP bet. After this conversion,
                    the bet cannot be cancelled. Otherwise, unlike an SP bet,
                    you can choose to cancel an Exchange bet once it has been
                    placed, even if you have chosen for the bet to persist or to
                    convert to an SP bet at the start of the event.{" "}
                  </li>
                  <li>
                    {" "}
                    If you choose to convert an unmatched Exchange lay bet to an
                    SP bet, the liability of your Exchange lay bet will be
                    converted into a liability for the SP bet. Your liability
                    for that SP bet will never be more than the liability for
                    the Exchange bet you had specified. However, the amount you
                    can win on the SP lay bet may differ from the amount you
                    would have won had the Exchange bet been matched, depending
                    on the final SP.{" "}
                  </li>
                </ul>
                <p>➢ Adjustments to SP bets for non-runners</p>
                <ul>
                  <li>
                    {" "}
                    For SP back bets, will at no point amend either the stake or
                    the odds requested by a customer, despite any non-runners or
                    withdrawals. However, customers who have selected the SP
                    limit option can reduce the minimum SP odds they are
                    prepared to accept on a selection.{" "}
                  </li>
                  <li>
                    {" "}
                    For SP lay bets on win markets, it will reduce a customer's
                    liability based on the reduction factor(s) of any
                    non-runner(s) and the reduction factor of the runner on
                    which the customer's bet has been placed. This is to ensure
                    that the balance between the backers' stakes and the layers'
                    liability reflects the revised market after the runner has
                    been removed. For example, if a horse with a 50% reduction
                    factor becomes a non-runner, then another horse in the same
                    market priced at about 5 (i.e. a reduction factor of 20%)
                    will change to a price of about 2.5. Therefore, the
                    liability on a £200 lay bet on that runner will need to
                    change to a liability of about £75 to ensure that a
                    balancing back bet will have the same £50 stake. This is
                    done by multiplying the liability by 37.5% (i.e. 100%-(50%/
                    (100%-20%))).{" "}
                  </li>
                  <li>
                    {" "}
                    Where an SP lay bet in a win market has a maximum odds limit
                    specified, this limit will be reduced by the reduction
                    factor of any non-runner, if the non-runner has a reduction
                    factor of at least 2.5%.{" "}
                  </li>
                  <li>
                    {" "}
                    For SP lay bets on place markets, it will still reduce a
                    customer's liability based on the reduction factor(s) of any
                    non-runner(s) but the calculation will be slightly
                    different, in line with the application of place market
                    reduction factors. The liability will be reduced by the
                    reduction factor of the removed runner. Where the lay bet on
                    a place market has a maximum odds limit specified, the
                    potential winnings on the bet (i.e. the odds – 1) will be
                    reduced by the reduction factor of the removed runner.{" "}
                  </li>
                  <li>
                    {" "}
                    Where an SP lay bet in a place market has a maximum odds
                    limit specified this limit will be reduced by the reduction
                    factor of any non-runner.{" "}
                  </li>
                  <li>
                    SP lay bets will not be cancelled when there is a
                    non-runner.
                  </li>
                </ul>
                <p>
                  ➢ Specific conditions for SP bets for non-runners in greyhound
                  racing markets
                </p>
                <ul>
                  <li>
                    {" "}
                    ALL SP bets will stand so long as your trap selection is not
                    vacant or after your bet placement a reserve runner is not
                    subsequently entered to run from your trap selection.{" "}
                  </li>
                  <li>
                    ALL unmatched ‘Convert to SP’ bets will be cancelled when a
                    reserve is added or a vacant trap removed.
                  </li>
                </ul>
                <br />
                <h2>11. 'Keep' bets option</h2>
                <ul>
                  <li>
                    {" "}
                    For markets that are scheduled to be turned in-play at the
                    'off', a customer can request that an unmatched Exchange bet
                    should not be cancelled when the market is turned in-play.
                    This is done by selecting the 'At In-Play: Keep' option in
                    the bet manager (and confirming that request) and means that
                    the unmatched bet persists when other unmatched bets are
                    cancelled at the start of the event.{" "}
                  </li>
                  <li>
                    {" "}
                    As described above, when a non-runner is removed from a
                    horseracing market (apart for late withdrawals as described
                    in the next bullet point below), it is policy to cancel
                    unmatched offers to lay all other horses in the market if
                    the non-runner has a reduction factor of 2.5% or greater for
                    win markets, or 4.0% or greater for place markets. In these
                    circumstances offers to lay a horse with the 'At In-Play:
                    Keep' option selected will not be cancelled. Instead the lay
                    odds offered in place markets will be reduced in proportion
                    with the reduction factors of any non-runner(s) and the same
                    will apply in win markets providing the relevant non-runner
                    has a reduction factor of at least 2.5%.{" "}
                  </li>
                  <li>
                    {" "}
                    When there is a late withdrawal, may not have time to remove
                    the non-runner from the market before turning it in-play. In
                    such cases if can determine that the late withdrawal is a
                    material runner (i.e. a selection with a reduction factor of
                    approx. 20% or greater in the win market), it reserves the
                    right to cancel ALL lay 'keep' bets (in both the win and ‘to
                    be placed’ markets) before turning the market in-play. If it
                    does not cancel lay 'keep' bets in the case of a late
                    withdrawal (for example, it may not be possible to know
                    which horse is withdrawn, at the time of the off), any such
                    bets placed before the off and matched in-play will remain
                    at the original selected price. This means that those lay
                    'keep' bets will not be subject to any reduction factor
                    which as a result of the late withdrawal, will be applied
                    after the completion of the race to bets matched at or
                    before the 'off'.{" "}
                  </li>
                  <li>
                    {" "}
                    Otherwise, in exceptional cases reserves the right to cancel
                    'keep' bets to protect customers but, unless stipulated in
                    the market rules or the market information, the general
                    principle is that at no point will a 'keep' bet be cancelled
                    by unless it remains unmatched when the market is closed
                    (for the final time) at the end of the event.{" "}
                  </li>
                  <li>
                    {" "}
                    For the avoidance of doubt this means that (for example)
                    when a Material Event occurs in a soccer match and other
                    unmatched bets are cancelled before the market is reopened,
                    a keep bet will not be cancelled.{" "}
                  </li>
                </ul>
                <br />
                <h2>12. Rules for Tote betting</h2>
                <ul>
                  <li>
                    {" "}
                    When you place a Tote bet on the platform (for example a UK
                    or South African Tote bet), you are betting against as
                    counterparty to your bet. I will then strike a corresponding
                    bet into the relevant Tote pool as applicable.{" "}
                  </li>
                  <li>
                    {" "}
                    A link to the rules which apply to Tote bets struck as
                    counterparty is provided within each of the relevant. If
                    there is any conflict between those rules and either: the
                    equivalent rules available on the relevant Tote website (for
                    example on the UK Tote website in the case of UK Tote bets);
                    or the equivalent rules applied by the relevant host
                    racetrack, the rules on the relevant Tote website or applied
                    by the relevant host racetrack, will prevail.{" "}
                  </li>
                </ul>
                <br />
                <h2>13. Miscellaneous</h2>
                <ul>
                  <li>
                    {" "}
                    All references to time periods in the Exchange Rules relate
                    to the time zone in which the event takes place. For
                    example, a reference to the start time of a football match,
                    relates to the local kick-off time.{" "}
                  </li>
                  <li>
                    {" "}
                    All information is done so in good faith. However, it cannot
                    accept liability for any errors or omissions in respect of
                    any information, such as the posting of prices, runners,
                    times, scores, results or general statistics.{" "}
                  </li>
                  <li>
                    {" "}
                    It reserves the right to correct any obvious errors and
                    shall take all reasonable steps to ensure markets are
                    administered with integrity and transparency.{" "}
                  </li>
                  <li>
                    {" "}
                    If an incorrect team or competitor name is displayed
                    (excluding minor spelling mistakes) or the incorrect number
                    of teams, competitors or outcomes is displayed in any
                    complete market or a market is otherwise loaded using
                    incorrect information (for example the application of an
                    incorrect exposure algorithm or a cross matching tool
                    utilising an incorrect algorithm) or includes any obvious
                    error such as the incorrect deployment of the cross matching
                    tool, then It reserves the right to suspend the market and
                    (providing it acts reasonably) to void all bets matched on
                    the market.{" "}
                  </li>
                  <li>
                    {" "}
                    Customers are responsible for ensuring that they satisfy
                    themselves that the selection on which they place a bet is
                    their intended selection. For example, in the case of a
                    competitor bearing the same name as another individual not
                    competing in the relevant event, the onus is on the customer
                    to ensure that they know which competitor It has loaded into
                    the relevant market and to ensure that they are placing
                    their bet on their chosen competitor.{" "}
                  </li>
                  <li>
                    It reserves the right at any time in its sole and absolute
                    discretion to suspend an Exchange market.
                  </li>
                  <li>
                    {" "}
                    It may, in its sole and absolute discretion, decide to
                    suspend betting on a market at any time (even if such
                    suspension is earlier than anticipated by the Exchange
                    Rules). In the interests of maintaining integrity and
                    fairness in the markets, it may also void certain bets in a
                    market or void a whole market in its entirety.{" "}
                  </li>
                  <li>
                    It reserves the right to amend the Exchange Rules at any
                    time.
                  </li>
                  <li>
                    It reserves the right to cancel unmatched bets to protect
                    customers at any time.
                  </li>
                  <li>On the settlement of any market, amounts relating to</li>
                  <li>winnings/losses on bets; and</li>
                  <li>commission charges</li>
                  <li>
                    {" "}
                    will be rounded up or down to the nearest two decimal places
                    (with the exception that rounding will always be down (and
                    never up) in respect of amounts relating to winnings/losses
                    in respect of BSP bets). By way of example, £3.333, will be
                    settled as £3.33, whereas £3.335, will be settled as £3.34
                    (except that it will be settled as £3.33 in respect of an
                    amount relating to winnings/losses in respect of BSP bets).{" "}
                  </li>
                  <li>
                    {" "}
                    The Exchange Rules have been prepared in various languages
                    other than English for reference only. In the event of any
                    differences between the English version and the non-English
                    version, the English version shall prevail.{" "}
                  </li>
                </ul>
                <br />
                <p>Part C - Specific sports rules</p>
                <br />
                <p>1. American Football</p>
                <ul>
                  <li>
                    {" "}
                    All markets will be settled on the result including overtime
                    unless stated otherwise in these American Football Rules or
                    in the Market Information (e.g. the half time/full time
                    market) or where the market has been unconditionally
                    determined. If the game results in a tie after overtime all
                    bets on the outright match winner market will be void.{" "}
                  </li>
                  <li>
                    {" "}
                    If a match does not start on the scheduled starting date and
                    is not completed within three days of the scheduled
                    completion date, all bets will be void except for those on
                    markets which have been unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    If a match starts but is later abandoned or postponed, then
                    within three days of the scheduled start date:(a) at least
                    55 minutes of play must have elapsed; or (b) an official
                    result must be 'called' by the relevant governing body;
                    otherwise all bets will be void, except for those on markets
                    which have been unconditionally determined. In those
                    instances, if the scores are tied then all bets on the
                    outright match winner market will be void.{" "}
                  </li>
                  <li>
                    For 'Individual player' markets, bets shall be void on any
                    player who does not start in any down during the match.
                  </li>
                </ul>
                <br />
                <p>2. Athletics</p>
                <ul>
                  <li>
                    {" "}
                    Where there is a presentation ceremony, markets will be
                    settled on the official result of the relevant governing
                    body at the time of the ceremony, regardless of any
                    subsequent disqualification or amendment to the result.{" "}
                  </li>
                  <li>
                    {" "}
                    If there is no presentation ceremony, outcomes will be
                    determined in accordance with the official result of the
                    relevant governing body, regardless of any subsequent
                    disqualification or amendment to the result (except if an
                    amendment is announced within 24 hours of the initial
                    settlement of the relevant market in order to correct an
                    error in reporting the result).{" "}
                  </li>
                  <li>
                    {" "}
                    Unless stated otherwise in the Market Information, if a
                    track or field event is abandoned, cancelled or postponed
                    and not completed within 7 days of the scheduled completion
                    date, all bets will be void except for those on markets
                    which have been unconditionally determined.{" "}
                  </li>
                </ul>
                <br />
                <p>3. Australian Rules Football</p>
                <ul>
                  <li>
                    {" "}
                    Bets apply to regular time excluding overtime. However, bets
                    on "Finals" matches apply to regular time including overtime
                    except for those on markets which have been unconditionally
                    determined or as stated otherwise in the Market Information
                    (e.g. half-time/full-time markets). If the game results in a
                    tie, dead-heat rules apply to bets on the match odds market.{" "}
                  </li>
                  <li>
                    {" "}
                    If a match does not start on the scheduled starting date and
                    is not completed within three days of the scheduled start
                    date, all bets will be void except for those on markets
                    which have been unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    If a match starts but is abandoned or postponed before
                    half-time and not completed within three days of the
                    scheduled start date then the match shall be deemed to be a
                    tie and settled on dead-heat rules. If a match starts but is
                    abandoned or postponed in the second half and not completed
                    within three days of the scheduled start date then the match
                    shall be settled on the official result of the relevant
                    governing body. In both instances, bets on the match odds
                    market will be settled as set out above and all other bets
                    will be void except for those on markets which have been
                    unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    All bets relating to any individual player will stand if
                    they are listed in any 22-player squad on the match day,
                    irrespective of whether they take any part in the match or
                    not.{" "}
                  </li>
                </ul>
                <br />
                <p>4. Baseball</p>
                <ul>
                  <li>
                    {" "}
                    These Baseball Rules apply to Major League Baseball (MLB),
                    World Baseball Classic and Japanese Baseball only. All other
                    baseball, including Finnish Baseball for example, will be
                    governed by the rules set out in the General Rules and the
                    Market Information.{" "}
                  </li>
                  <li>
                    If a match does not start on the scheduled starting date,
                    all bets will be void.
                  </li>
                  <li>
                    {" "}
                    If an MLB match starts but is later abandoned or postponed
                    and an official result is not declared within three days of
                    the scheduled start date, all bets will be void, except for
                    those on markets which have been unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    If a Japanese Baseball match starts but is later abandoned
                    or postponed and the match is not completed within three
                    days of the scheduled start date, all bets will be void
                    except for those on markets which have been unconditionally
                    determined.{" "}
                  </li>
                  <li>
                    All bets will include extra innings, unless otherwise stated
                    in the Market Information.
                  </li>
                  <li>
                    {" "}
                    All bets on 'Total runs' or 'Run line' markets will be void
                    unless there have been at least 9 innings completed (8 1/2
                    if the home team is ahead) except those on market which have
                    been unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    In "Listed" markets, bets will be void unless both named
                    pitchers listed in the market start as pitchers in the first
                    fielding innings for their respective teams.{" "}
                  </li>
                </ul>
                <br />
                <p>5. Basketball</p>
                <ul>
                  <li>
                    {" "}
                    Bets will be settled on the result including overtime unless
                    stated otherwise in the Market Information or where the
                    market has been unconditionally determined or as set out in
                    these Basketball rules.{" "}
                  </li>
                  <li>
                    If a match does not start on the scheduled start date then
                    all bets will be void.
                  </li>
                  <li>
                    {" "}
                    If a match starts but is later abandoned or postponed then,
                    within three days of the scheduled start date, (a) at least
                    43 minutes of play must have elapsed in any NBA match or 35
                    minutes of play must have elapsed in any other match; or (b)
                    an official result must be 'called' by the relevant
                    governing body; otherwise all bets will be void, except for
                    those on markets which have been unconditionally determined.
                    In these instances, if the scores are tied then dead heat
                    rules will apply to bets on the outright match winner
                    market.{" "}
                  </li>
                </ul>
                <br />
                <p>6. Boxing and Mixed Martial Arts</p>
                <ul>
                  <li>
                    {" "}
                    All individual bout markets will be settled according to the
                    official result of the relevant governing body immediately
                    after the end of the fight, regardless of any subsequent
                    disqualification or amendment to the result (except if an
                    amendment is announced within 24 hours of the initial
                    settlement of the relevant market in order to correct an
                    error in tallying the points or in order to correct an error
                    in reporting the result).{" "}
                  </li>
                  <li>
                    {" "}
                    If a fight is abandoned, cancelled or postponed and not
                    completed within three days of the scheduled start date, or
                    a boxer is withdrawn or substituted before the first-round
                    bell, all bets will be void.{" "}
                  </li>
                  <li>
                    {" "}
                    If a boxer retires before the start of a round, fails to
                    answer the bell, or is disqualified for any reason between
                    rounds, the fight will be deemed to have finished at the end
                    of the previous round.{" "}
                  </li>
                  <li>
                    If one of the boxers is disqualified they will be deemed a
                    loser.
                  </li>
                  <li>
                    {" "}
                    If the scheduled duration of the fight is altered in time or
                    number of rounds after the loading of the relevant markets,
                    then all bets will be void.{" "}
                  </li>
                  <li>
                    {" "}
                    If the scheduled venue is changed after the market has been
                    loaded so that the new venue is changed to a venue in a
                    different country then all bets will be void. For all other
                    changes to the scheduled venue bets will stand.{" "}
                  </li>
                  <li>
                    In mixed martial arts, if a fight is declared a 'no
                    contest', a draw or a technical draw then all bets will be
                    void.
                  </li>
                </ul>
                <br />
                <p>7. Cricket</p>
                <p>➢ General</p>
                <ul>
                  <li>
                    {" "}
                    If a ball is not bowled during a competition, series or
                    match then all bets will be void except for those on any
                    market that has been unconditionally determined (e.g. in the
                    'Completed Match' market).{" "}
                  </li>
                  <li>
                    {" "}
                    If a match is shortened by weather, all bets will be settled
                    according to the official result (including for limited
                    overs matches, the result determined by the Duckworth Lewis
                    method).{" "}
                  </li>
                  <li>
                    {" "}
                    In the event of a match being decided by a bowl-off or toss
                    of the coin, all bets will be void except for those on
                    markets that have been unconditionally determined.{" "}
                  </li>
                </ul>
                <p>➢ Test matches</p>
                <ul>
                  <li>
                    {" "}
                    If a match starts but is later abandoned for any reason
                    other than weather (which may include but is not limited to:
                    dangerous or unplayable wicket or outfield; pitch vandalism;
                    strike or boycott; crowd protests/violence; stadium damage;
                    acts of terrorism; and acts of God), It reserves the right
                    to void all bets, except for those on markets that have been
                    unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    If the match is not scheduled to be completed within five
                    days after the original scheduled completion date, then all
                    bets on markets for this event will be void, except for bets
                    on any markets that have been unconditionally determined.{" "}
                  </li>
                </ul>
                <p>➢ Limited Over matches</p>
                <ul>
                  <li>
                    {" "}
                    If a match is declared "No Result'', bets will be void on
                    all markets for the event except for those markets which
                    have been unconditionally determined or where the minimum
                    number of overs have been bowled as laid out in the market
                    specific information.{" "}
                  </li>
                  <li>
                    {" "}
                    In the event of a new toss taking place on a scheduled
                    reserve day for a limited over match all bets that were
                    placed after 30 minutes before the original scheduled start
                    of play on the first day will be made void. This rule
                    relates to all markets except those that have been
                    unconditionally determined (e.g. in the win the toss and
                    toss combination markets).{" "}
                  </li>
                </ul>
                <p>➢ Super Over rule</p>
                <ul>
                  <li>
                    {" "}
                    Which team will win this Super Over? This market will be
                    suspended on site and activated once Betfair are aware a
                    Super Over is to be played. The market will be turned
                    in-play at the start of the Super Over. This market will not
                    be actively managed therefore it is the responsibility of
                    all customers to manage their positions. This market will be
                    settled based on the number of runs scored by each team in
                    the initial Super Over. For the avoidance of doubt, if
                    scores are tied at the completion of both innings in the
                    Super Over then the market will be settled as a Dead Heat
                    except in cases where more than one Super Over is played,
                    where it will be settled on the winner of the final over.
                    Any tie breaker that may be used to determine a winner
                    including but not limited to higher number of boundaries,
                    higher number of sixes, losing fewer wickets, coin toss etc.
                    do not count for the purposes of this market.{" "}
                  </li>
                </ul>
                <br />
                <p>8. Cycling</p>
                <ul>
                  <li>
                    {" "}
                    Where there is a presentation ceremony, markets will be
                    settled on the official result of the relevant governing
                    body at the time of the ceremony, regardless of any
                    subsequent disqualification or amendment to the result.{" "}
                  </li>
                  <li>
                    {" "}
                    If there is no presentation ceremony, outcomes will be
                    determined in accordance with the official result of the
                    relevant governing body, regardless of any subsequent
                    disqualification or amendment to the result (except if an
                    amendment is announced within 24 hours of the initial
                    settlement of the relevant market in order to correct an
                    error in reporting the result).{" "}
                  </li>
                </ul>
                <br />
                <p>9. Darts</p>
                <ul>
                  <li>
                    If a match is not completed for any reason then bets on the
                    match odds market will be void.
                  </li>
                  <li>
                    {" "}
                    If a match is not completed for any reason then bets on 'any
                    correct score' or 'next leg/game/set' market will be void
                    unless the market has been unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    If a match is not completed for any reason then bets on any
                    handicap market will be void unless the market has been
                    unconditionally determined.{" "}
                  </li>
                </ul>
                <br />
                <p>10. Financial markets</p>
                <p>➢ Indices</p>
                <ul>
                  <li>
                    {" "}
                    Intraday, Midday and PM markets will be settled on the first
                    Bloomberg price stamp past the market closure time
                    regardless of any subsequent amendment to the result (except
                    if an amendment is announced within 24 hours of the initial
                    settlement of the relevant market in order to correct an
                    error in reporting the result).{" "}
                  </li>
                  <li>
                    {" "}
                    All Daily markets, weekly markets and end of day Intraday
                    markets will be settled on the official close price
                    following any relevant auction period regardless of any
                    subsequent amendment to the result (except if an amendment
                    is announced within 24 hours of the initial settlement of
                    the relevant market in order to correct an error in
                    reporting the result).{" "}
                  </li>
                </ul>
                <p>➢ Equities</p>
                <ul>
                  <li>
                    {" "}
                    All equity markets will be settled on the official close
                    price given by Bloomberg regardless of any subsequent
                    amendment to the result (except if an amendment is announced
                    within 24 hours of the initial settlement of the relevant
                    market in order to correct an error in reporting the
                    result).{" "}
                  </li>
                  <li>
                    {" "}
                    Should a share split be announced, the market will be
                    determined by the net change in value of the new share price
                    at the close of the day’s trading.{" "}
                  </li>
                </ul>
                <p>➢ Interest Rates</p>
                <ul>
                  <li>
                    {" "}
                    Interest rate markets will be settled once the result is
                    regardless of any subsequent amendment to the result (except
                    if an amendment is announced within 24 hours of the initial
                    settlement of the relevant market in order to correct an
                    error in reporting the result).{" "}
                  </li>
                </ul>
                <br />
                <p>11. House Price</p>
                <ul>
                  <li>
                    {" "}
                    For settlement purposes, the Standardized Average Price
                    (seasonally adjusted) provided by HBOS will be used and
                    markets will be settled once the result is regardless of any
                    subsequent amendment to the result (except if an amendment
                    is announced within 24 hours of the initial settlement of
                    the relevant market in order to correct an error in
                    reporting the result).{" "}
                  </li>
                  <li>
                    {" "}
                    It will only use prices provided by HBOS. If the HBOS
                    figures are discontinued or not available for any relevant
                    period then all bets will be void unless stated otherwise in
                    the Market Information.{" "}
                  </li>
                  <li>
                    {" "}
                    All Quarterly markets may be incomplete markets and
                    therefore additional "runners" may be added at any time to
                    reflect the movements in house prices.{" "}
                  </li>
                </ul>
                <p>➢ Currency markets</p>
                <ul>
                  <li>
                    {" "}
                    All Currency markets will be settled using the first Spot
                    price given by Bloomberg past the market closure time
                    regardless of any subsequent amendment to the result (except
                    if an amendment is announced within 24 hours of the initial
                    settlement of the relevant market in order to correct an
                    error in reporting the result).{" "}
                  </li>
                </ul>
                <br />
                <p>12. Golf</p>
                <p>➢ General</p>
                <ul>
                  <li>
                    Tournament bets will only be settled if the minimum number
                    of holes stipulated in the Market Information has been
                    completed.
                  </li>
                  <li>
                    {" "}
                    Where there is a presentation ceremony, markets will be
                    settled on the official result of the relevant governing
                    body at the time of the ceremony, regardless of any
                    subsequent disqualification or amendment to the result.{" "}
                  </li>
                  <li>
                    {" "}
                    If there is no presentation ceremony, outcomes will be
                    determined in accordance with the official result of the
                    relevant governing body, regardless of any subsequent
                    disqualification or amendment to the result (except if an
                    amendment is announced within 24 hours of the initial
                    settlement of the relevant market in order to correct an
                    error in reporting the result).{" "}
                  </li>
                  <li>
                    If a player does not start a tournament then all bets on
                    that player will be void.
                  </li>
                  <li>
                    Any player starting a tournament but withdrawing or being
                    disqualified before the end of the tournament will be
                    settled as a loser.
                  </li>
                  <li>
                    If a tournament is shortened and settles the tournament
                    markets then all bets matched after the last completed round
                    will be void.
                  </li>
                  <li>
                    {" "}
                    In any 'to qualify' market for any tournament the winners
                    are the number of golfers that qualify for the tournament
                    under the terms set out in the Market Information, whether
                    they compete in the tournament or not. Markets will be
                    settled after the qualifying stage and any subsequent
                    disqualification or amendment to results will not count.{" "}
                  </li>
                  <li>
                    {" "}
                    If a Tournament/Round is restarted from the beginning, all
                    bets placed after the official off time will be void, except
                    on markets which have been unconditionally determined, which
                    will stand. Bets on 2 or 3 balls will only be void if
                    matched after the tee time of the relevant 2 or 3 balls.{" "}
                  </li>
                  <li>
                    {" "}
                    If it does not suspend a tournament market and cancel
                    unmatched bets at the time stated in the market rules but
                    the market is turned in-play at a later time, all bets
                    matched between the start of a day’s play and the time the
                    market is turned in-play will be void.{" "}
                  </li>
                </ul>
                <p>➢ Tournament match betting (i.e. 72-hole match bets)</p>
                <p />
                <ul>
                  <li>
                    If a player withdraws without playing a stroke all bets on
                    the relevant market will be void.
                  </li>
                  <li>
                    {" "}
                    If all players fail to complete a particular round for any
                    reason the winner is the player with the lowest total score
                    after the previous round.{" "}
                  </li>
                  <li>
                    {" "}
                    If a player is disqualified or withdraws during any round
                    they will be settled as a loser providing at least one other
                    player completes that round.{" "}
                  </li>
                  <li>
                    Should all players fail to complete the first round then all
                    bets will be void.
                  </li>
                </ul>
                <p>➢ Round betting markets (e.g. 2 balls, 3 balls etc.)</p>
                <p />
                <ul>
                  <li>
                    If a player withdraws without playing a stroke in that round
                    all bets on the relevant market will be void.
                  </li>
                  <li>
                    {" "}
                    If one player fails to complete a round for any reason other
                    than a withdrawal or disqualification then all bets will be
                    void except for those on markets which have been
                    unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    Any player withdrawing or being disqualified having played a
                    stroke in that round will be settled as a loser providing at
                    least one other player completes that round.{" "}
                  </li>
                  <li>
                    Should all players fail to complete the round then all bets
                    will be void.
                  </li>
                </ul>
                <p>
                  ➢ Stroke play hole-by-hole markets (i.e. performance of a
                  named player on a given hole)
                </p>
                <p />
                <ul>
                  <li>
                    {" "}
                    Should a hole not be completed for any reason all bets on
                    that hole will be void unless the market has been
                    unconditionally determined.{" "}
                  </li>
                  <li>
                    Markets are settled on completion of the hole and any
                    subsequent penalties or disqualification will not be taken
                    into account.
                  </li>
                </ul>
                <p>
                  ➢ Match play hole-by-hole markets (i.e. performance of players
                  against each other on a given hole)
                </p>
                <p />
                <ul>
                  <li>
                    {" "}
                    With the exception of a player or team conceding a hole
                    (where they are deemed a loser), if a hole is not completed
                    by any player or team (other than for withdrawal or
                    disqualification) all bets on that hole will be void unless
                    the market has been unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    Any player or team withdrawing or being disqualified having
                    played a stroke on that hole will be settled as a loser
                    providing at least one other player completes that hole.{" "}
                  </li>
                  <li>
                    If any player or team does not play a stroke on a hole all
                    bets will be void.
                  </li>
                  <li>
                    Markets are settled on completion of the hole and any
                    subsequent penalties or disqualification will not be taken
                    into account.
                  </li>
                </ul>
                <br />
                <p>13. Greyhound racing</p>
                <p>➢ General</p>
                <ul>
                  <li>
                    {" "}
                    All bets (excluding those struck on ante-post and Australian
                    licensed markets) are placed on trap numbers. Greyhound
                    names are displayed for information purposes only.{" "}
                  </li>
                  <li>
                    {" "}
                    Markets will be determined according to the official result
                    at the time the track gives the result green light status,
                    either in the form of an announcement or by display.
                    Subsequent disqualifications, appeals or amendments to the
                    result will be disregarded.{" "}
                  </li>
                  <li>
                    {" "}
                    If a non-runner or reserve runner is declared, then all bets
                    prior to the update of the market on It will be void and all
                    unmatched bets including ‘Take SP’ and ‘keep’ bets will be
                    cancelled (except for certain SP bets as set out in
                    Paragraph 10.5 of Part B above).{" "}
                  </li>
                  <li>
                    If there are no finishers in any race or any race is
                    declared void before the official result is declared then
                    all bets will be void.
                  </li>
                  <li>
                    If the scheduled venue is changed after the market has been
                    loaded by It, all bets will be void.
                  </li>
                  <li>
                    {" "}
                    In ante-post markets, all bets on individual greyhounds
                    stand whether the greyhound runs or not. All ante-post bets
                    will be void if the competition is abandoned or the venue is
                    changed.{" "}
                  </li>
                </ul>
                <p>➢ Trap Challenge</p>
                <ul>
                  <li>
                    {" "}
                    Bets on 'Trap challenge' markets are based on which trap
                    results in the most winners during a race meeting, unless
                    stated otherwise in the Market Information.{" "}
                  </li>
                  <li>
                    These markets are unaffected by any changes due to
                    non-runners or reserve runners.
                  </li>
                  <li>
                    If a race results in a dead heat each trap will receive a
                    half-win, with a 3-way dead heat giving a one third-win, and
                    so on.
                  </li>
                  <li>
                    Standard dead heat rules apply if two or more traps have an
                    equal number of winners at the end of both meetings.
                  </li>
                  <li>
                    {" "}
                    If a whole meeting is abandoned all bets are void. However,
                    if at least one race has been completed then bets on these
                    markets will stand.{" "}
                  </li>
                </ul>
                <p>➢ Multi-trap or Multiplied Trap Numbers</p>
                <ul>
                  <li>
                    {" "}
                    'Multi-trap' or 'multiplied trap numbers' bets are on the
                    cumulative sum of the winning trap multiplied by the second
                    trap for each race during a race meeting, unless stated
                    otherwise in the Market Information. If the cumulative sum
                    is not a round number then that sum shall be rounded up to
                    give the applicable cumulative sum.{" "}
                  </li>
                  <li>
                    These markets are unaffected by any changes due to
                    non-runners or reserve runners.
                  </li>
                  <li>
                    {" "}
                    If a race is cancelled, void or abandoned or if there is
                    only one finisher in a race, an eight runner-greyhound races
                    will be allocated 20 points and all other races will be
                    allocated 12 points.{" "}
                  </li>
                  <li>
                    {" "}
                    If a whole meeting is abandoned all bets are void however if
                    at least one race has been completed then bets on these
                    markets will stand with the remaining races allocated 20
                    points for eight runner greyhound races and 12 points for
                    all other races.{" "}
                  </li>
                  <li>Re-run races will be treated as cancelled races.</li>
                  <li>
                    {" "}
                    If there is a dead heat for the winner between two
                    greyhounds then to calculate the applicable multi-trap
                    number for that race the trap numbers for the greyhounds
                    involved in the dead heat will be multiplied by each other
                    to give the applicable multi-trap number.{" "}
                  </li>
                  <li>
                    {" "}
                    If there is a dead heat for the winner between three or more
                    greyhounds then to calculate the applicable multi-trap
                    number for that race the trap numbers for the greyhounds
                    involved in the dead heat will be added up and divided by
                    the number of greyhounds involved in the dead heat and the
                    resulting number will be multiplied by itself to give the
                    applicable multi-trap number (even if not a round number).
                    By way of example: traps 1, 3 and 6 dead heat for first. Add
                    these numbers up gives 10; divide this number by the number
                    of greyhounds in the dead heat (3) which gives a resulting
                    number of 3.33; multiply 3.33 by itself to give 11.11.{" "}
                  </li>
                  <li>
                    {" "}
                    If there is a dead heat for the runner up between two or
                    more greyhounds then to calculate the applicable multi-trap
                    number for that race the trap numbers for the greyhounds
                    involved in the dead heat will be added up and divided by
                    the number of greyhounds involved in the dead heat and the
                    resulting number will be multiplied by the number of the
                    winner to give the applicable multi-trap number (even if not
                    a round number). By way of example: trap 1 wins and traps 3
                    and 6 dead heat for second. Adding the numbers of the dead
                    heating runners up gives 9; divide this number by the number
                    of greyhounds in the dead heat (2) which gives a resulting
                    number of 4.5; multiple 4.5 by the winning number (1) to
                    give 4.5.{" "}
                  </li>
                </ul>
                <p>➢ Winning Distances</p>
                <ul>
                  <li>
                    {" "}
                    'Winning distances' bets are on the sum of the winning
                    distances for all races during a race meeting, unless
                    otherwise stated in the Market Information.{" "}
                  </li>
                  <li>
                    The maximum winning distance in any race is 10 lengths.
                  </li>
                  <li>
                    If there is only one finisher in any race the winning
                    distance will be deemed to be 10 lengths.
                  </li>
                  <li>
                    If a race is cancelled, void or abandoned the winning
                    distance will be deemed to be 2 lengths.
                  </li>
                  <li>Re-run races will be treated as cancelled races.</li>
                  <li>
                    For distances below half a length, the following scale will
                    be applied: Short-head 0.1; Head 0.2; Neck 0.3.
                  </li>
                  <li>
                    {" "}
                    The sum of all the winning distances will be rounded to the
                    nearest whole number at the end of the meeting (rounded up,
                    if half) and bets will be settled on this result.{" "}
                  </li>
                  <li>
                    {" "}
                    If a whole meeting is abandoned all bets are void. However,
                    if at least one race has been completed then the remaining
                    races are allocated 2 lengths each and bets will stand.{" "}
                  </li>
                </ul>
                <p>➢ Match Bets</p>
                <ul>
                  <li>
                    {" "}
                    'Match Bets' for a race are determined by the greyhound with
                    the highest finishing position in that race. If neither
                    greyhound finishes the race bets are void. If only one of
                    the greyhounds finishes the race, that greyhound will be
                    deemed the winner. If a non-runner or reserve runner is
                    declared then all bets will be void.{" "}
                  </li>
                  <li>
                    {" "}
                    'Match Bets' for progress in a competition are determined by
                    the greyhound which qualifies to the furthest round (whether
                    it runs in the further round or not). If the greyhounds
                    concerned fail to qualify in the same round of the
                    competition then the market will be settled as a dead heat,
                    irrespective of their finishing positions in their
                    individual heats.{" "}
                  </li>
                </ul>
                <p>➢ Reverse Forecasts</p>
                <ul>
                  <li>
                    'Reverse Forecast' markets are determined by those
                    greyhounds placed first and second in a race.
                  </li>
                  <li>
                    If a dead heat affects the reverse forecast then dead heat
                    rules apply.
                  </li>
                </ul>
                <p>➢ Place markets</p>
                <ul>
                  <li>
                    {" "}
                    The number of winners in 'to be placed' markets is as set
                    out in the Market Information and is determined with
                    reference to the number of runners when the market is
                    loaded.{" "}
                  </li>
                  <li>
                    {" "}
                    Once opened, the number of winners in 'to be placed' markets
                    (as set out in the Market Information) will not be affected
                    by further non-runners. If the number of potential winners
                    is equal to or is greater than the number of runners, all
                    bets in this market will be void.{" "}
                  </li>
                </ul>
                <p>➢ To Reach the Final and To Qualify markets</p>
                <ul>
                  <li>
                    {" "}
                    'Reach the Final' markets shall be determined by the first
                    six greyhounds who qualify from the Semi Finals of the
                    competition and be settled as such regardless of whether
                    they go on to run in the final or not.{" "}
                  </li>
                  <li>
                    {" "}
                    'To qualify' markets shall be determined by the greyhounds
                    that qualify in the relevant heats irrespective of whether
                    they run in the next round or not.{" "}
                  </li>
                  <li>
                    {" "}
                    The 'reach the final' or 'to qualify' markets will be
                    settled after the qualifying stages and any subsequent
                    disqualification or amendment to results will not count.{" "}
                  </li>
                </ul>
                <p>➢ Ante-post</p>
                <ul>
                  <li>
                    {" "}
                    If a greyhound listed in an ante-post market dies, it will
                    use the available information to determine the time of the
                    greyhound's death. It will then, acting reasonably,
                    determine whether the greyhound was a 'material runner' in
                    the market. In determining whether a greyhound is a material
                    runner, it will look at the general price of the greyhound
                    immediately before the greyhound's death, in the market and
                    in the wider betting market. Broadly, it will consider a
                    greyhound to be a material runner if it is deemed to have an
                    approximate chance of winning of 8-10 per cent or better.{" "}
                  </li>
                  <li>
                    {" "}
                    If It determines that the greyhound was a 'material runner',
                    then all bets struck in the market (on all selections)
                    between the time of death and the suspension of the market
                    will be void and all unmatched bets will be cancelled before
                    the market is reopened.{" "}
                  </li>
                  <li>
                    {" "}
                    If It determines that the greyhound was not a 'material
                    runner', then just bets struck on the greyhound in question
                    between the time of death and the suspension of the market
                    will be void and unmatched bets will not be cancelled before
                    the market is reopened.{" "}
                  </li>
                  <li>
                    {" "}
                    Bets matched on greyhounds after they have forfeited their
                    entry at a particular entry stage or where they have not
                    qualified by the applicable qualifying date will be voided
                    unless the race in question has a subsequent supplementary
                    entry stage.{" "}
                  </li>
                  <li>
                    {" "}
                    Where an event is postponed or rescheduled to another day at
                    the same venue then ante-post bets will stand unless entries
                    are reopened in which case all bets will be void.{" "}
                  </li>
                </ul>
                <p>➢ Australian Specific Non-Runner Rules</p>
                <ul>
                  <li>
                    Notwithstanding any of the above, the following rules apply
                    to declared non-runners in Australian greyhound markets.
                  </li>
                  <li>
                    {" "}
                    If a greyhound becomes a notified non-runner after the
                    market is loaded but prior to the commencement of the race
                    it will be removed and all bets on the market, matched prior
                    to the update of the market will be voided.{" "}
                  </li>
                  <li>
                    {" "}
                    If, following the completion of a race, the stewards declare
                    a greyhound a non-runner, it will resettle the market and
                    will void all bets that were placed on that runner only. It
                    will then apply a reduction factor to all bets placed on the
                    winner (or place getters in the case of place markets) based
                    on that runner’s weighted average price.{" "}
                  </li>
                </ul>
                <br />
                <h2>14. Horseracing</h2>
                <p>➢ General</p>
                <ul>
                  <li>
                    {" "}
                    All individual race markets will be determined according to
                    the official result at the time of the 'weigh-in'
                    announcement (or equivalent). Subsequent disqualifications,
                    appeals or amendments to the result will be disregarded.{" "}
                  </li>
                  <li>
                    If a race is abandoned or otherwise declared void, or in the
                    event of a walkover, all bets on that race will be void.
                  </li>
                  <li>
                    If the scheduled venue is changed after the market has been
                    loaded, all bets will be void.
                  </li>
                  <li>
                    Where a race does not take part on its scheduled day, all
                    bets will be void.
                  </li>
                  <li>
                    If a scheduled surface type is changed (e.g. turf to dirt)
                    all bets will stand.
                  </li>
                  <li>
                    {" "}
                    Horseracing Exchange Multiples are based on ‘day of the
                    race’ markets (and not ante-post markets). Horseracing
                    ante-post rules do not therefore apply in relation to
                    horseracing Exchange Multiples.{" "}
                  </li>
                </ul>
                <p>➢ Ante-post</p>
                <ul>
                  <li>
                    Subject to the points below, in ante-post markets, all bets
                    on an individual horse stand whether the horse runs or not.
                  </li>
                  <li>
                    For GB and Irish racing: if a horse is balloted out, all
                    bets on that horse will be void.
                  </li>
                  <li>
                    {" "}
                    If a horse listed in an ante-post market dies, it will use
                    the available information to determine the time of the
                    horse's death. It will be acting reasonably, determine
                    whether the horse was a 'material runner' in the market. It
                    will undertake a similar determination in cases where it is
                    advised through official channels that a runner has been
                    formally scratched from an ante-post race. In determining
                    whether a horse is a material runner, it will consider the
                    general price of the horse immediately before the horse's
                    death (or immediately before being advised it has been
                    scratched), in the market and in the wider betting market.
                    Broadly, it will consider a horse to be a material runner if
                    it is deemed to have an approximate chance of winning of
                    8-10 per cent or better.{" "}
                  </li>
                  <li>
                    {" "}
                    If it determines that the horse was a 'material runner',
                    then all bets struck in the market (on all selections)
                    between the time of death (or the time that the formal
                    scratching notification was created) and the suspension of
                    the market will be void and all unmatched bets will be
                    cancelled before the market is reopened.{" "}
                  </li>
                  <li>
                    {" "}
                    If it determines that the horse was not a 'material runner',
                    then just bets struck on the horse in question between the
                    time of death (or the time that the formal scratching
                    notification was created) and the suspension of the market
                    will be void and unmatched bets will not be cancelled before
                    the market is reopened.{" "}
                  </li>
                  <li>
                    {" "}
                    Bets matched on horses after they have forfeited their entry
                    at a particular entry stage or where they have not qualified
                    by the applicable qualifying date will be voided unless the
                    race in question has a subsequent supplementary entry stage.{" "}
                  </li>
                  <li>
                    {" "}
                    Please be aware that runners who have not been entered at
                    the various entry stages may be removed from relevant race
                    markets and all matched customer bet prices set to 1.0 even
                    if there are later supplementary stages. Should it appear
                    likely that a specific runner may actually be supplemented
                    into the race this runner will be reinstated with all
                    matched customer bets set back to the original prices.{" "}
                  </li>
                  <li>
                    No Non-Runner Rule' reductions are made to ante-post bets.
                  </li>
                  <li>
                    {" "}
                    All relevant bets will be void where an event is: abandoned
                    and not rescheduled; or postponed and rescheduled to another
                    venue; or postponed and rescheduled to another day at the
                    same venue with entries for the race being reopened or
                    reverting back to a previous entry or declaration stage
                    provided any additional horses are entered or re-entered and
                    such entries or declarations are considered, in absolute
                    discretion, material to the betting on the race. For the
                    avoidance of doubt, where a race is postponed and
                    rescheduled to another day at the same venue, relevant bets
                    will stand where entries/declarations at the time of
                    postponement remain unaltered or include any non-material
                    addition(s) prior to the rescheduled race.{" "}
                  </li>
                </ul>
                <p>➢ Place Terms for Exchange markets</p>
                <ul>
                  <li>
                    {" "}
                    The number of winners in 'to be placed' markets is as set
                    out in the Market Information and is determined with
                    reference to the number of runners when the market is
                    loaded.{" "}
                  </li>
                  <li>
                    Once opened, the number of winners in 'to be placed' markets
                    will not be affected by further non-runners.
                  </li>
                  <li>
                    {" "}
                    If the number of winners stated in the Market Information is
                    equal to or is greater than the number of runners, all bets
                    in this market will be void.{" "}
                  </li>
                  <li>
                    {" "}
                    If the number of placed horses is less than the number of
                    potential winners listed in the Market Information, the
                    winners will only be the placed horse or horses.{" "}
                  </li>
                </ul>
                <p>➢ EW markets</p>
                <ul>
                  <li>
                    {" "}
                    The place portion of any "Each Way" bet will be settled
                    according to the number of 'places' and at the fraction of
                    the win portion profit stated in the market information.{" "}
                  </li>
                  <li>
                    {" "}
                    For example: “EW Terms: 1/5th odds, 3 places”. That “1/5th”
                    is applied to the traditional or fractional odds, which are
                    1 less than decimal odds, so the calculation of the Place
                    odds corresponding to Win odds of 8.0 in this market is
                    ((8.0 – 1) / 5) + 1 = 2.4.{" "}
                  </li>
                  <li>
                    {" "}
                    Once opened, the number of places in 'EW” markets will not
                    be affected by further non-runners unless the number of
                    ‘places’ which are offered on the ‘Each Way’ market is equal
                    to or exceeds the number of runners in which case, the place
                    portion of any EW bet will be voided at settlement.{" "}
                  </li>
                </ul>
                <p>➢ Winning Distance Bets</p>
                <ul>
                  <li>
                    {" "}
                    Unless stated otherwise in the Market Information, winning
                    distances' bets are on the sum of the winning distances from
                    each race on one particular day at a race meeting.{" "}
                  </li>
                  <li>
                    {" "}
                    The winning distance in a race will be the officially
                    declared distance between the first two horses past the
                    post. However, if either the first or second horse past the
                    post is disqualified because: (i) of an incorrect weight
                    carried; (ii) they have taken the wrong course; (iii) the
                    jockey of either horse fails to weigh in, or weighs in light
                    after the race; then in each case the winning distance shall
                    be that between the first and second horse under the
                    official result.{" "}
                  </li>
                  <li>
                    {" "}
                    For the purpose of this bet, the maximum distance on any
                    individual race will be 12 lengths for Flat races and 30
                    lengths for National Hunt races (which includes National
                    Hunt Flat Races) and these distances will be applied where
                    only one horse finishes a race. For distances below half a
                    length, the following scale will be applied:{" "}
                  </li>
                  <ul>
                    <li>Nose: 0.05 length</li>
                    <li>Short-head: 0.1</li>
                    <li>Head: 0.2</li>
                    <li>Short-neck: 0.25</li>
                    <li>Neck: 0.3</li>
                  </ul>
                  <li>
                    {" "}
                    If a meeting has three or more races abandoned or declared
                    void, then all 'winning distance' bets will be void, unless
                    the entire market has been unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    When only one or two races are abandoned or declared void,
                    then 'winning distance' bets will be settled with a default
                    distance used for each abandoned or void race. The default
                    distances are 2 lengths for Flat races and 10 lengths for
                    National Hunt races.{" "}
                  </li>
                  <li>
                    In the case of a walkover the following distances will be
                    applied:
                  </li>
                  <ul>
                    <li>Flat: 5 lengths</li>
                    <li>National Hunt: 12 lengths</li>
                  </ul>
                </ul>
                <p>➢ Non-Runner Rule</p>
                <ul>
                  <li>
                    {" "}
                    Non-runner rule relates to the adjustment of odds on bets
                    already matched when a horse in a race is declared a
                    non-runner. In order to make the adjustment applies a
                    reduction factor to the remaining runners. The reduction
                    factor allocated to a non-runner is a calculation (the
                    details of which are described below) of that horse's
                    chances of winning (or being placed, etc. as appropriate)
                    and is applied to bets already matched on the other runners
                    in the relevant market or markets.{" "}
                  </li>
                  <li>
                    {" "}
                    Any horse listed when the relevant market is loaded which
                    does not subsequently come under starter's orders is deemed
                    to be a non-runner.{" "}
                  </li>
                  <li>
                    {" "}
                    When the market is loaded each horse is given a 'reduction
                    factor', based on a forecast price, which is expressed as a
                    percentage. These reduction factors may be updated
                    periodically at the discretion based on trading in the
                    market, but after approximately 15 minutes (approximately 5
                    minutes for Australian and US markets) from the scheduled
                    'off' time of a given race, they will be updated only in
                    exceptional circumstances.{" "}
                  </li>
                  <li>
                    {" "}
                    Reductions will be made to both win and place markets but
                    applied differently (as described below), and horses will
                    have a different reduction factor for each market.{" "}
                  </li>
                  <li>
                    {" "}
                    As soon as becomes aware that a horse is an official
                    non-runner or a highly likely non-runner, following a
                    statement to the press from connections, the following will
                    happen:{" "}
                  </li>
                  <li>
                    All matched bets on that horse will be void and the horse
                    will be removed from the market.
                  </li>
                  <li>
                    {" "}
                    In the win market: if the reduction factor of the non-runner
                    is 2.5% or greater, the traded price of all the matched bets
                    on the remaining horses will be reduced by an amount equal
                    to the non-runner's final reduction factor and all the
                    unmatched offers to lay will be cancelled. If the
                    non-runner's reduction factor is less than 2.5%, reductions
                    will not be applied and unmatched bets will not be
                    cancelled.{" "}
                  </li>
                  <li>
                    {" "}
                    In the place market, the reduction factor of all non-runners
                    will be applied (even if less than 2.5%) and the potential
                    winnings in relation to matched bets on the remaining horses
                    will be reduced by an amount equal to the non-runner's final
                    reduction factor. Only if the non-runner's reduction factor
                    is 4.0% or greater will all the unmatched offers to lay be
                    cancelled.{" "}
                  </li>
                  <li>
                    All the reduction factors on the remaining horses will be
                    adjusted to reflect their improved chance of winning.
                  </li>
                  <li>
                    {" "}
                    Reduction factors are not applied to bets which are struck
                    in-play. However, if a market is turned in-play prematurely
                    by error (or, for example, there is a false start), all bets
                    matched during this time will be subject to any later
                    reduction factor, provided the market is turned out of play
                    before the race commences. In the event of a late
                    withdrawal, it reserves the right to remove the runner after
                    completion of the race. In this case only those bets matched
                    prior to the off will be affected by a reduction factor.{" "}
                  </li>
                  <li>
                    {" "}
                    In the event of a non-runner being removed from a race in
                    error or following incorrect information regarding a
                    runner’s participation, it will reinstate both the runner
                    and all previously matched bets associated with that runner.
                    All bets made between the time of withdrawal and
                    reinstatement will be void in both the place market and the
                    win market. The reduction factor applied to matched bets at
                    the time of withdrawal will be reversed and the original
                    prices will become valid.{" "}
                  </li>
                  <li>
                    {" "}
                    Any non-runners will be removed from the relevant markets in
                    the order in which they are brought to attention. If It
                    becomes aware of more than one non-runner at the same time,
                    it will remove the non-runners from the relevant markets in
                    race card order.{" "}
                  </li>
                  <li>
                    {" "}
                    If a runner is not included in a market because of an error
                    or because of incorrect information regarding a runner’s
                    participation, It reserve the right to introduce the missing
                    runner into the market at any time prior to settlement (even
                    after the race has been run), provided that It has
                    determined that the missing runner is not a material runner
                    (i.e. a selection with a reduction factor of approx. 2.5% or
                    less in the win market). In such circumstances, all pre-play
                    unmatched and matched bets will stand, however if the runner
                    is not introduced before the start of the race, all in-play
                    bets will be void. However, if the missing runner is deemed
                    to be a material runner, then the malformed market will be
                    void and a new market will be loaded where possible.{" "}
                  </li>
                </ul>
                <p>➢ How the Reductions are applied for Exchange markets</p>
                <ul>
                  <li>
                    In the win market, reductions will be made on the traded
                    price.
                  </li>
                  <li>
                    {" "}
                    For example: if the non-runner's final reduction factor is
                    25% the traded price on all previously matched bets on other
                    horses will be reduced by 25% - traded price of 8.0 would
                    become 6.0 etc. And these might be further reduced if
                    another horse is subsequently declared a non-runner.{" "}
                  </li>
                  <li>
                    {" "}
                    In the EW Market, reductions will be made on the traded win
                    price. The advertised place terms will then apply to the
                    revised win prices.{" "}
                  </li>
                  <li>
                    {" "}
                    For example: if the non-runner's final reduction factor is
                    25% the traded price on all previously matched bets on other
                    horses will be reduced by 25% - - traded price of 8.0 would
                    become 6.0. If each Way terms were 1/5th odds for 3 places,
                    the corresponding price for the Place portion of the bet
                    would reduce from 2.4 to 2.0.{" "}
                  </li>
                  <li>
                    In the place market, reductions will be made to the
                    potential winnings on the bet only, and not the traded
                    price.
                  </li>
                  <li>
                    {" "}
                    For example: if the non-runner's final reduction factor is
                    25% the potential winnings on all previously matched bets on
                    the other horses will be reduced by 25% - a traded price of
                    8.0 would become 6.25. For example, a £10 bet on a horse to
                    be placed at a traded price of 8.0 would provide winnings of
                    £70. If there is a non-runner with a reduction factor of 25%
                    in the race, that factor will be applied to the £70 of
                    potential winnings leaving potential winnings of £52.50.
                    Therefore, the revised traded price will be 6.25.{" "}
                  </li>
                  <li>
                    {" "}
                    The traded price may be further reduced if any other
                    horse(s) is subsequently declared a non-runner, however odds
                    cannot be reduced below 1.01.{" "}
                  </li>
                  <li>
                    {" "}
                    Reserves: A reserve runner may appear in the relevant
                    markets but will have a non-applicable reduction factor
                    until It has received confirmation that it is a confirmed
                    runner, in which case an applicable reduction factor may
                    apply to it.{" "}
                  </li>
                  <li>
                    {" "}
                    For the avoidance of doubt, any reduction factor applicable
                    to a non-runner replaced by a reserve, will be applied to
                    all bets struck on the relevant markets, prior to the
                    removal from those markets of such non-runner. Likewise,
                    should a reserve runner become a confirmed runner but
                    subsequently become a non-runner, any reduction factor
                    applicable to such non-runner will be applied to all bets
                    struck on the relevant markets, prior to the removal from
                    those markets of such non-runner.{" "}
                  </li>
                </ul>
                <p>➢ Additional rules</p>
                <ul>
                  <li>
                    Card numbers are posted as a guide only: bets are placed on
                    a named horse.
                  </li>
                  <li>Horses will not be coupled.</li>
                  <li>
                    {" "}
                    Where any horse(s) runs for purse money only it is deemed a
                    non-runner for betting purposes. Should this result in the
                    number of possible winners stated in the relevant Market
                    Information being equal to or greater than the number of
                    runners in the relevant market, all bets in the market will
                    be void.{" "}
                  </li>
                </ul>
                <br />
                <h2>15. Ice Hockey</h2>
                <ul>
                  <li>
                    {" "}
                    All bets on "Regular Time" markets will be settled on the
                    result at the end of regular time, excluding overtime. All
                    bets on " Moneyline " markets will be settled at the result
                    at the end of regular time including "overtime and any
                    shootouts that may be played".{" "}
                  </li>
                  <li>
                    {" "}
                    'Puck Line' markets (i.e. handicap markets for NHL (National
                    Hockey League) matches) will be settled on the final result
                    including any overtime and any shootouts that may be played.
                    All 'handicap' markets on other matches will be settled on
                    the result at the end of regular time, excluding overtime.{" "}
                  </li>
                  <li>
                    {" "}
                    If a match does not start on the scheduled start date and is
                    not completed within three days of the scheduled start date,
                    all bets will be void except for those on markets which have
                    been unconditionally determined.{" "}
                  </li>
                  <li>
                    {" "}
                    If a match starts but is later abandoned or postponed then,
                    within three days of the scheduled start date, (a) at least
                    55 minutes of play must have elapsed in any match; or (b) an
                    official result must be 'called' by the relevant governing
                    body; otherwise all bets will be void, except for those
                    which have been unconditionally determined. In these
                    instances, if the scores are tied then for " Moneyline "
                    matches (where no tie is offered) dead heat rules will apply
                    to bets on the outright match winner market.{" "}
                  </li>
                </ul>
                <br />
                <h2>16. Rowing</h2>
                <ul>
                  <li>
                    {" "}
                    If a crew or individual starts a race but does not complete
                    it then they will be deemed a loser providing at least one
                    other crew or individual completes the race. If no crew or
                    individual completes a race then all bets will be void.{" "}
                  </li>
                  <li>
                    If a regatta is cancelled for any reason, all bets will be
                    void, except those on markets which have been
                    unconditionally determined.
                  </li>
                  <li>
                    {" "}
                    Where there is a presentation ceremony, markets will be
                    settled on the official result of the relevant governing
                    body at the time of the ceremony, regardless of any
                    subsequent disqualification or amendment to the result.{" "}
                  </li>
                  <li>
                    {" "}
                    If there is no presentation ceremony, outcomes will be
                    determined in accordance with the official result of the
                    relevant governing body, regardless of any subsequent
                    disqualification or amendment to the result (except if an
                    amendment is announced within 24 hours of the initial
                    settlement of the relevant market in order to correct an
                    error in reporting the result).{" "}
                  </li>
                </ul>
                <br />
                <h2>17. Rugby Union and Rugby League</h2>
                <ul>
                  <li>
                    {" "}
                    'Tournament points' and 'tournament tries' bets will apply
                    to all playing time, including any extra-time in any match
                    where an official result is declared.{" "}
                  </li>
                  <li>
                    {" "}
                    For 'time of first try' bets, if the try is scored in the
                    second half, the first half is deemed to have lasted 40
                    minutes, regardless of stoppage-time. If no try is scored,
                    or the try is scored in second half stoppage-time or
                    extra-time, the result is 80.{" "}
                  </li>
                  <li>
                    {" "}
                    For the purpose of markets involving tries, penalty tries
                    will count with the exception of 'first individual try
                    scorer' markets where penalty tries will not count.{" "}
                  </li>
                  <li>
                    {" "}
                    If a match starts but is abandoned before its completion,
                    all bets will be void unless an official result is declared
                    by the applicable governing body. Where an official result
                    has been declared by the official governing body, that
                    official result will govern match and handicap market
                    settlement but all other markets will be void unless their
                    result has already been determined at the point of
                    abandonment (i.e. at the point of abandonment, it would not
                    have been possible for the outcome of the bet to change had
                    the match continued to its natural conclusion). By way of
                    example, if 37 points have been scored at the time a match
                    is abandoned: (i) a bet placed on 35 points or more to be
                    scored would be settled as a winning bet, (ii) a bet placed
                    on 30-35 points to be scored would be settled as a losing
                    bet and (iii) a bet placed on 40-45 points to be scored
                    would be void. In such circumstances, bets on last try
                    scorer / team to score last / team to score last try / last
                    scoring play would all be void.{" "}
                  </li>
                </ul>
                <br />
                <h2>18. Snooker and Pool</h2>
                <ul>
                  <li>
                    {" "}
                    In the event of a match starting but not being completed,
                    the player progressing to the next round will be deemed the
                    winner (or in the case of the final the player declared the
                    winner).{" "}
                  </li>
                  <li>
                    If a match is not completed for any reason then bets on 'any
                    correct score' or 'next frame' market will be void.
                  </li>
                  <li>
                    {" "}
                    If a match is not completed for any reason, bets on any
                    handicap market will be void unless the market has been
                    unconditionally determined.{" "}
                  </li>
                </ul>
                <br />
                <h2>19. Soccer</h2>
                <li>
                  {" "}
                  If it does not suspend a market on time for the occurrence of
                  a Material Event, it reserves the right to void bets unfairly
                  matched after the Material Event has occurred. Voiding of
                  these bets may take place during the event or retrospectively
                  once a game is completed.{" "}
                </li>
                <li>
                  {" "}
                  If a match has not started (or believes that a match will not
                  have started) by 23:59 (local time) on its scheduled start
                  date, then all bets will be void unless it has knowledge that
                  the match has been rescheduled to be played within three days
                  of its original start date.{" "}
                </li>
                <li>
                  {" "}
                  If a match starts but is later abandoned or postponed and
                  believes that the match will not have been completed by 23:59
                  (local time) on its scheduled start date, then all markets,
                  with the exception of any unconditionally determined markets,
                  will be void unless it has knowledge that the match has been
                  rescheduled to be played within three days of its original
                  start date. If it does have knowledge that the game will be
                  played within three days and the game is played within three
                  days, then all bets will stand except if the match is
                  restarted from the beginning. If the match is restarted from
                  the beginning then all bets matched before the market went
                  in-play will stand, but any bets placed in-play will be void,
                  except for any bets placed in-play on markets which have been
                  unconditionally determined, which will stand.{" "}
                </li>
                <li>
                  {" "}
                  For Friendly matches, all bets apply to the full duration of
                  play according to the match officials, plus any stoppage time.
                  If a friendly match starts but is later abandoned or postponed
                  and is not completed (i.e. the full duration of play according
                  to match officials, plus any stoppage time) within three days
                  of the scheduled start date, all bets will be void except for
                  those on markets which have been unconditionally determined.
                  In the case of ambiguity over the official result from match
                  officials, the outcome will be determined (acting reasonably)
                  using information from independent sources.{" "}
                </li>
                <li>
                  {" "}
                  If an official fixture lists different team details to those
                  listed (for example, the team name, reserves, age group,
                  gender, etc.), then all bets matched on the affected markets
                  will be void. In all other cases, bets will stand (including
                  instances where a team name is listed without specifying the
                  term 'XI' in the name). If an official fixture is shown on the
                  website under an incorrect competition name, then it reserves
                  the right to void all bets matched on the affected markets.{" "}
                </li>
                <li>
                  {" "}
                  If a team is relegated from a league because, at the end of a
                  season, it has finished within the relegation positions which
                  are relevant to that league (i.e. usually any of the bottom
                  three league positions), bets on that team to be relegated
                  will be settled as winning bets. If a team is otherwise
                  disqualified, thrown out or removed from a league (i.e. in
                  circumstances other than those where it has finished the
                  season within the relevant relegation positions): (i) if such
                  team is disqualified, thrown out or removed from the league
                  before the relevant season has started, all bets on the
                  affected market will be void (and a new market will
                  subsequently be loaded) and (ii) if such team is disqualified,
                  thrown out or removed from the league after the relevant
                  season has started (or a determination is made by the
                  applicable governing body, during the season, that the team
                  will be thrown out or removed from the league following the
                  season’s conclusion), all bets on the affected team will be
                  void. For the avoidance of doubt, if a points deduction is
                  imposed on a team such that it finishes the season within the
                  relegation places which are relevant to the applicable league,
                  bets on that team to be relegated will be settled as winning
                  bets.{" "}
                </li>
                <li>
                  {" "}
                  The relevant season will be deemed to have started once the
                  first league game has been played. For the purposes of this
                  rule, markets relating to individual matches will not be
                  deemed to be "affected markets".{" "}
                </li>
                <li>
                  {" "}
                  'Shirt numbers' bets will refer to the shirt number allocated
                  at the start of the match. 'Shirt numbers' bets will include
                  own-goal scorers. Any player whose shirt bears no number will
                  be allocated the number 12.{" "}
                </li>
                <li>
                  {" "}
                  For 'time of first goal' bets (i.e. "First Goal Odds"
                  markets), the first half is deemed to last 45 minutes,
                  regardless of stoppage time. Also for these markets, please
                  note that the "0 - 10 Minutes" selection covers the first 10
                  minutes of the match. In other words, it runs from 0:00 until
                  just before the clock hits 10:00. The "11 – 20 Minutes"
                  selection runs from 10:00 until just before the clock hits
                  20:00. The same principle applies to each of the other
                  selections in this market.{" "}
                </li>
                <li>
                  {" "}
                  For 'top goal scorer' markets only the goals scored in the
                  league or competition stated in the Market Information count.
                  For example, if a player joins a club mid-season any goals
                  scored in a different league will not count, however goals
                  scored for a different club in the same league will count. Own
                  goals will not count.{" "}
                </li>
                <li>
                  {" "}
                  In markets which relate to the number of incidents to occur,
                  such as 'number of corners', these will be determined on the
                  basis of the number taken, rather than awarded.{" "}
                </li>
                <li>
                  {" "}
                  For markets that relate to the number of bookings given, the
                  number of corners taken, any goal scorer or the time of a
                  particular goal, the result will be determined (acting
                  reasonably) using information from independent sources. In
                  such cases, if any new information comes into the public
                  domain within 48 hours of settlement, then it shall (acting
                  reasonably) determine either: (i) whether the market should be
                  reinstated or resettled in light of this new information; or
                  (ii) to wait for further information before deciding whether
                  to reinstate or resettle the market. Except it has announced
                  that it is waiting for further information, any information
                  that comes into the public domain more than 48 hours after a
                  market has been settled shall not be considered (regardless of
                  whether or not such information may have led to a different
                  result).{" "}
                </li>
                <br />
                <h2>20. Swimming</h2>
                <ul>
                  <li>
                    {" "}
                    Where there is a presentation ceremony, markets will be
                    settled on the official result of the relevant governing
                    body at the time of the ceremony, regardless of any
                    subsequent disqualification or amendment to the result.{" "}
                  </li>
                  <li>
                    {" "}
                    If there is no presentation ceremony, outcomes will be
                    determined in accordance with the official result of the
                    relevant governing body, regardless of any subsequent
                    disqualification or amendment to the result (except if an
                    amendment is announced within 24 hours of the initial
                    settlement of the relevant market in order to correct an
                    error in reporting the result){" "}
                  </li>
                </ul>
                <br />
                <h2>21. Tennis</h2>
                <ul>
                  <li>
                    {" "}
                    If a player or pairing retires or is disqualified in any
                    match, the player or pairing progressing to the next round
                    (or winning the tournament in the case of a final) will be
                    deemed the winner. However, if less than one set has been
                    completed at the time of the retirement or disqualification
                    then all bets relating to that individual match will be
                    void.{" "}
                  </li>
                  <li>
                    {" "}
                    All bets relating to the number of occurrences of certain
                    events within a tournament will be void if the tournament is
                    reduced in length, postponed or cancelled, except for those
                    on markets which have been unconditionally determined.{" "}
                  </li>
                  <li>
                    All bets will stand regardless of changes to scheduled
                    venues, including any changes to a different type of
                    surface.
                  </li>
                  <li>
                    {" "}
                    If the scheduled duration of a match is reduced or increased
                    in the number of games/sets required to win, all bets will
                    be void except for those on markets which have been
                    unconditionally determined. Please note that this does not
                    apply to ‘Match Odds’ or ‘Set Winner’ markets on Davis Cup
                    matches or ‘dead rubber’ matches that have been shortened
                    from five sets to three sets after the market has been
                    loaded, provided that the match has been shortened in
                    accordance with the competition’s rules.{" "}
                  </li>
                  <li>
                    {" "}
                    Where markets are offered on individual games or sets within
                    a match, a retirement or disqualification during a game or
                    set will render bets on that game or set market and all
                    individual game or set markets void except those on markets
                    which have been unconditionally determined.{" "}
                  </li>
                </ul>
                <br />
                <h2>
                  22. Winter sports (which may include, amongst others, Alpine
                  Skiing, Biathlon, Cross-Country Skiing and Ski-Jumping)
                </h2>
                <ul>
                  <li>
                    {" "}
                    Where there is a presentation ceremony, markets will be
                    settled on the official result of the relevant governing
                    body at the time of the ceremony, regardless of any
                    subsequent disqualification or amendment to the result.{" "}
                  </li>
                  <li>
                    {" "}
                    If there is no presentation ceremony, outcomes will be
                    determined in accordance with the official result of the
                    relevant governing body, regardless of any subsequent
                    disqualification or amendment to the result (except if an
                    amendment is announced within 24 hours of the initial
                    settlement of the relevant market in order to correct an
                    error in reporting the result).{" "}
                  </li>
                  <li>
                    {" "}
                    Unless stated otherwise in the Market Information, if an
                    event is abandoned, postponed or cancelled, all bets will be
                    void unless (a) the event is completed at the same venue
                    within 7 days of the official scheduled completion date; or
                    (b) a result is 'called' by the relevant governing body.{" "}
                  </li>
                  <li>
                    {" "}
                    The General Rules will apply for 'match bets'. However, in
                    respect of "Nordic Combined" if all competitors involved in
                    the match bet do not start both sections of the event (ski
                    jumping and cross country) then bets will be void.{" "}
                  </li>
                </ul>
                <br />
              </div>
            </div>
          </div>
          <div className="first-step-content">
            <div className="step-check">
              <input
                id="checkbox_01"
                name="checkbox_01"
                type="checkbox"
                className="hidden"
              />
              <label for="checkbox_01">Do not show again. </label>
            </div>
            <div className="first-step-btn">
              <Button onClick={() => logoutUser()}>Decline</Button>{" "}
              <Button onClick={() => onSubmit(changePass)}>I Accept</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
