import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, DropdownButton, Table } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/helpers";
import { Dummy } from "../constraints/constants";
const MarketDepth = ({ setShowMarketDepthPopup, data, detail }) => {
  const [select, setSelect] = useState(
    data?.runner?.length > 0 ? data?.runner[0]?.RunnerName : ""
  );
  const [team, setTeam] = useState(
    data?.runner?.length > 0 ? data?.runner[0]?.SelectionId : ""
  );
  const [setting, setSetting] = useState({
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 40,
  });
  const [result, setResult] = useState([]);
  const [back, setBack] = useState([]);
  const [lay, setLay] = useState([]);
  const getData = async () => {
    const { status, data } = await apiGet(apiPath.matchOddsRisk, {
      marketId: detail?.marketId,
    });
    if (status === 200) {
      if (data.success) {
        if (data?.results?.data?.length > 0) {
          let result = data?.results?.data[0]?.dataLog;
          if (result?.length > 0) {
            let updated = result
              ?.map((res) => {
                return res?.runners?.map((item) => {
                  return {
                    ...item,
                    time: res?.timestamp,
                    formated: obj.timeFormat(res?.timestamp),
                  };
                });
              })
              .flat();

            setResult(updated);
            let currentSelection = updated?.filter((res) => {
              return res?.selectionId == team;
            });
            let lay = currentSelection
              ?.map((item) => {
                return item?.layOdds?.map((obj) => {
                  return { ...obj, time: item?.time, formated: item?.formated };
                });
              })
              .flat();
            let back = currentSelection
              ?.map((item) => {
                return item?.backOdds?.map((obj) => {
                  return { ...obj, time: item?.time, formated: item?.formated };
                });
              })
              .flat()
              .sort((a, b) => {
                return new Date(b.time) - new Date(a.time);
              });
            setSetting((prev) => {
              return { ...prev, initialSlide: lay?.length - 3 };
            });
            setLay(lay);
            setBack(back);
          }
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handel = (id) => {
    setTeam(id);
    let currentSelection = result?.filter((res) => {
      return res?.selectionId == id;
    });
    let lay = currentSelection
      ?.map((item) => {
        return item?.layOdds?.map((obj) => {
          return { ...obj, time: item?.time, formated: item?.formated };
        });
      })
      .flat();

    let back = currentSelection
      ?.map((item) => {
        return item?.backOdds?.map((obj) => {
          return { ...obj, time: item?.time, formated: item?.formated };
        });
      })
      .flat()
      .sort((a, b) => {
        return new Date(b.time) - new Date(a.time);
      });
    setSetting((prev) => {
      return { ...prev, initialSlide: lay?.length - 3 };
    });
    setLay(lay);
    setBack(back);
  };

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };



  return (
    <div className="market-depth-modal slide-up">
      <div className="market-title text-white">
        <h4 className="text-white">Market Depth</h4>
        <Button
          className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"
          onClick={() => setShowMarketDepthPopup(false)}
        >
          <RxCross2 />
        </Button>
      </div>

      <div className="market-depth-body">
        <div className="match-point">
          {" "}
          <span className="master-pass">{detail?.eventName}</span>
          <span className="master-pass">Match Odds</span>
        </div>

        <div className="select-container">
          <DropdownButton title={select}>
            {data?.runner?.map((res) => {
              return (
                <Dropdown.Item
                  onClick={() => {
                    handel(res?.SelectionId);
                    setSelect(res?.RunnerName);
                    setTeam(res?.SelectionId);
                  }}
                >
                  {res?.RunnerName}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>

          {/* <DropdownButton >
            {data?.runner?.map((res) => {
              return (
                <Dropdown.Item
                onClick={() => {
                  setSelect(select !== first ? first : second);
                  setSelectId(selectId !== firstId ? firstId : secondId);
                  let id = selectId !== firstId ? firstId : secondId;
                  setLay(
                    data?.layOdds?.filter((res) => {
                      return res?.ri == id;
                    })
                  );
                  setBack(
                    data?.backOdds?.filter((res) => {
                      return res?.ri == id;
                    })
                  );
                }}
                eventKey={select !== first ? first : second}
                >
                  {res?.RunnerName}
                </Dropdown.Item>
              );
            })}
          </DropdownButton> */}
        </div>

        <div className="mark-depth-total">
          <Table className=" border">
            <tbody>
              <tr>
                <td className="text-center">
                  Total matched
                  <strong class="d-block">{data?.totalMatched || 0.0}</strong>
                </td>
                <td className="text-center">
                  Selection Volume
                  <strong class="d-block">{data?.totalMatched || 0.0}</strong>
                </td>
                <td className="text-center">
                  Last price
                  <strong class="d-block">
                    {lay?.length > 0 ? lay[lay?.length - 1]?.price : "0.0"}
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div className="balance-label position-relative  exchange-label">
          Price, Exchange Available and Traded
        </div>

        <div className="d-flex justify-content-between p-2">
          <span
            onClick={previous}
            style={{ padding: "2px 4px", cursor: "pointer" }}
            className="bet-name d-flex align-items-center lay rounded fw-normal"
          >
            <FaAngleLeft size={14} className="text-success" /> to Lay
          </span>

          <span
            onClick={next}
            style={{ padding: "2px 4px", cursor: "pointer" }}
            className="bet-name d-flex align-items-center  back rounded fw-normal"
          >
            to Back <FaAngleRight size={14} vclassName="text-danger" />
          </span>
        </div>
        {lay?.length > 0 && (
          <div className="to-back-to-lay slider-container">
            {/* <Table className="">
              <thead>
                <tr>
                  {lay?.length > 0 &&
                    lay?.map((res) => {
                      return (
                        <th className="fw-normal text-center bet-name lay rounded-0">
                          <strong className="d-block">{res?.rt}</strong>
                          {res?.bv}
                        </th>
                      );
                    })}
                  {back?.length > 0 &&
                    back?.map((res) => {
                      return (
                        <th className="fw-normal text-center bet-name back rounded-0">
                          <strong className="d-block">{res?.rt}</strong>
                          {res?.bv}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1553.25</td>
                  <td>1553.25</td>
                  <td>1553.25</td>
                  <td>1553.25</td>
                  <td>1553.25</td>
                  <td>1553.25</td>
                </tr>
              </tbody>
            </Table> */}

            <Slider
              className="mb-1"
              ref={(slider) => {
             
                sliderRef = slider;
              }}
              {...setting}
            >
              {lay?.map((res) => {
                return (
                  <div style={{ border: "1px solid grey" }}>
                    <div
                      style={{
                        margin: "0",
                        borderLeft: "1px solid white",
                        borderRight: "1px solid white",
                      }}
                      className="fw-normal text-center bet-name lay rounded-0 p-1"
                    >
                      <strong className="d-block">{res?.price}</strong>
                      {res?.size}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "4px",
                        background: "white",
                        fontSize: "10px",
                        border: "1px solid #d4e0e5",
                      }}
                    >
                      {res?.formated}
                    </div>
                  </div>
                );
              })}

              {back?.map((res) => {
                return (
                  <div style={{ border: "1px solid grey" }}>
                    <div
                      style={{
                        margin: "0",
                        borderLeft: "1px solid white",
                        borderRight: "1px solid white",
                      }}
                      className="fw-normal text-center bet-name back rounded-0 p-1"
                    >
                      <strong className="d-block">{res?.price}</strong>
                      {res?.size}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "4px",
                        background: "white",
                        fontSize: "10px",
                        border: "1px solid #d4e0e5",
                      }}
                    >
                      {res?.formated}
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketDepth;
